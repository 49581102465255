import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PagesService }  from '../../services/pages.service';
import { SectionsService }  from '../../services/sections.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

@Component({
  selector: 'app-pages-index',
  templateUrl: './pages-index.component.html',
  styleUrls: ['./pages-index.component.scss']
})

export class PagesIndexComponent implements OnInit {
  pagesOriginal: any = [];
  pages : any = [];
  sortColumns = {
  	'title' : { type: 'string', direction: ''},
  	'active' : { type: 'numeric', direction: ''}
  };
  section : any = {};

  paginationConfig: PaginationConfig = new PaginationConfig();
  
  constructor(
  	public pagesService: PagesService,
  	public sectionsService: SectionsService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private route: ActivatedRoute,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
	this.all();
  }
  
  all () {
  	let sectionId = this.route.snapshot.paramMap.get('id');
    let self = this;
    
    let url = 'pages/all';
    
    this.ngxLoader.start();
	this.pagesService.read(url).then((response) => {
	  self.pagesOriginal = response;
      self.pages = response;
      self.ngxLoader.stop();
	});
  }
  
  delete (page) {
    let self = this;
    this.ngxLoader.start();
	this.pagesService.delete('pages/delete/'+page.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  confirmDelete(page) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je '+page.name+' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(page);
		}
	});
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
	let keyword = event.target.value;
	this.pages = this.pagesOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.pages = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
}

<h1>
	Logs
</h1>
<div class="table-search mb-1">
	<input (keyup)="search($event, logsOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col" width="50">Code</th>
      <th scope="col">Bericht</th>
      <th scope="col" width="200">Datum</th>
      <th scope="col" width="150">IP</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let log of logs | paginate: paginationConfig; let i = index" [ngClass]="{ 'd-none': log.hidden == true }">
		<td>{{log.code}}</td>
		<td><div class="text-wrap text-monospace alert-warning">{{log.message}}<br /><br /><a href="{{log.url}}" class="font-weight-bold">{{log.url}}</a></div></td>
		<td>{{log.createdAt | date:'short'}}</td>
		<td>{{log.ip}}</td>
    </tr>
  </tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
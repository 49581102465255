import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SectionsService } from '../../services/sections.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: 'app-banners-form',
	templateUrl: './banners-form.component.html',
	styleUrls: ['./banners-form.component.scss']
})
export class BannersFormComponent implements OnInit {
	error: boolean = false;
	item: any = {};
	errors: any = [];
	mutateForm;
	errormessage = false;
	errorposition = false;
	successmessage = false;
	imageSource: string = '';

	youtubeSafeURL = null;

	constructor(
		public service: SectionsService,
		private route: ActivatedRoute,
		private ngxLoader: NgxUiLoaderService,
		private sanitizer: DomSanitizer
	) { }

	ngOnInit(): void {
		this.fetchItem();

	}

	removeImages() {
		this.imageSource = null;
		this.item.imageSource = null;
	}

	randomDate(start, end) {
		return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
	}

	fetchItem() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
		this.ngxLoader.start();
		this.service.read('banners/details/' + id + '').then((response) => {
			self.item = response;

			if (self.item['startAt'] != undefined) {
				self.item['startAt'] = self.item['startAt'].replace(" 00:00:00", "");
			}

			if (self.item['endAt'] != undefined) {
				self.item['endAt'] = self.item['endAt'].replace(" 00:00:00", "");
			}

			console.log(self.item)

			if (self.item.image != undefined) {
				self.item.imageSource = self.item.image + "?v=" + this.randomDate(new Date(2012, 0, 1), new Date());
			}
			self.ngxLoader.stop();
		});
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		let postData = {};
		postData = self.item;

		if (this.imageSource != '') {
			postData['imageSource'] = this.imageSource;
		}

		if (postData['startAt'] != undefined) {
			postData['startAt'] += " 00:00:00";
		}

		if (postData['endAt'] != undefined) {
			postData['endAt'] += " 00:00:00";
		}

		if (postData['imageSource'] != undefined && postData['imageSource'].includes("api.")) {
			delete (postData['imageSource']);
		}

		this.service.save('banners/save', postData).then((response) => {
			self.item = response;
			location.reload();
			self.ngxLoader.stop();
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	handleImageChange(e) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}
		reader.onload = this._handleImageReaderLoaded.bind(this);
		reader.readAsDataURL(file);
	}

	_handleImageReaderLoaded(e) {
		let reader = e.target;
		this.item.imageSource = reader.result;
	}

	triggerImageSelect(event) {
		let element: HTMLElement = document.getElementById('image-select') as HTMLElement;
		element.click();
	}

	convertYouTube() {
		if (this.item.youtube.length != 0) {
			this.item.youtube = "https://www.youtube-nocookie.com/embed/" + this.getYoutubeVideoId(this.item.youtube);
			this.youtubeSafeURL = null;
		}
	}

	getYoutubeVideoId(url) {
		const regExp = /^.*(?:(?:youtu.be\/|v\/|watch\?v=)|embed\/)([\w-_]+)(?:\?[^#]*#)?$/i;
		const match = url.match(regExp);

		return match && match[1];
	}

	embed(url) {
		if(this.youtubeSafeURL == null) {
			this.youtubeSafeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
		}

		return this.youtubeSafeURL;
	}
}

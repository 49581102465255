<h1>
	<span routerLink="/sections" class="btn btn-info"><i class="fas fa-arrow-left"></i></span>
	{{section['name']}}
	<button routerLink="/chapter/form/0" class="btn btn-sm btn-primary">Toevoegen</button>
</h1>
<div class="table-search mb-1">
	<input (keyup)="search($event, chapters)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th width="10">#</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', chapters)" scope="col">Module</th>
      <th class="sortable" scope="col" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', chapters)" width="100">Status</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let chapter of chapters let i = index">
    <td>{{(i+1)}}</td>
		<td><a routerLink="/chapter/form/{{chapter.id}}">{{chapter.name}}</a></td>
		<td>
			<div *ngIf="chapter.active==1">Actief</div>
			<div *ngIf="chapter.active==0">Niet actief</div>
		</td>
	    <td>
	    	<a routerLink="/chapter/form/{{chapter.id}}"><i class="fas fa-edit"></i></a>  &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(chapter)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>
  </tbody>
</table>

import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ChaptersService }  from '../../services/chapters.service';
import { SectionsService }  from '../../services/sections.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-chapters-index',
  templateUrl: './chapters-index.component.html',
  styleUrls: ['./chapters-index.component.scss']
})

export class ChaptersIndexComponent implements OnInit {
  chapters : any = [];
  chaptersOriginal: any = [];
  sortColumns = {
  	'name' : { type: 'string', direction: ''},
  	'sectionName' : { type: 'string', direction: ''},
  	'position' : { type: 'numeric', direction: ''},
  	'location' : { type: 'string', direction: ''},
  	'studentCount' : { type: 'numeric', direction: ''},
  	'classroomCount' : { type: 'numeric', direction: ''},
  	'active' : { type: 'numeric', direction: ''}
  };
  section : any = {};
  
  constructor(
  	public chaptersService: ChaptersService,
  	public sectionsService: SectionsService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private route: ActivatedRoute,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
	this.all();
  }
  
  all () {
  	let sectionId = this.route.snapshot.paramMap.get('id');
    let self = this;
    
    let url = (sectionId == '' || sectionId === null) ? 'chapters' : 'chapters/list/'+sectionId+'';
    
    this.ngxLoader.start();
	this.chaptersService.read(url).then((response) => {
      self.chapters = response;
	  self.chaptersOriginal = response;
	  console.log(response)
      self.ngxLoader.stop();
	});
	
	if (sectionId !== null) {
		this.sectionsService.read('sections/details/'+sectionId+'').then((response) => {
	      self.section = response;
		});
	}
  }
  
  delete (chapter) {
    let self = this;
    this.ngxLoader.start();
	this.chaptersService.delete('chapters/delete/'+chapter.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  confirmDelete(chapter) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je '+chapter.name+' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(chapter);
		}
	});
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search(event, collection) {
    let keyword = event.target.value;
    this.chapters = this.chaptersOriginal.slice();
    if (keyword != '') {
      this.chapters = this.collectionManager.search(keyword, collection).slice();
    }
  }
}

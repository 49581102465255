import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { ConfigService } from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { SectionsService } from '../../services/sections.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-check-youtube',
  templateUrl: './check-youtube.component.html',
  styleUrls: ['./check-youtube.component.scss']
})
export class CheckYoutubeComponent implements OnInit {
  videosOriginal: any = [];
  videos: any = [];
  frontUrl: '';
  pageIndex: number;
  currentSectionId: '';
  sectionIndex: '';
  sections: any = [];
  chapters: any = [];

  activeChapters: any = [];
  filteredBlockQuotes: any = [];

  storedKeyword: string = '';

  constructor(
    public authenticationService: AuthenticationService,
    public apiConnectionService: ApiConnectionService,
    private ngxLoader: NgxUiLoaderService,
    public sectionsService: SectionsService,
    public configService: ConfigService,
    public collectionManager: CollectionManagerService,
    private sanitizer: DomSanitizer
  ) {
    this.frontUrl = this.configService.get('front_url');
    this.pageIndex = 0;

    let self = this;
    this.sectionsService.read('sections/all').then((response) => {
      self.sections = response;
    });
  }

  ngOnInit(): void {
    this.fetchItems();
  }

  setCurrentSectionIndex(index) {
    this.videosOriginal = [];
    this.videos = [];
    this.activeChapters = [];
    this.sectionIndex = index;
    this.fetchItems();
  }

  getSanitizedHtml(imageSrc: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(imageSrc);
  }

  fetchItems() {
    let self = this;
    this.apiConnectionService.read('lessons/videos/' + self.sectionIndex + '').then((response) => {
      console.log(response)
      self.videosOriginal = response['videos'];
      self.videos = response['videos'];
      self.setVideos();
    });;
  }

  search(event) {
    let keyword = '';

    if (event === false) {
      keyword = this.storedKeyword;
    } else {
      keyword = event.target.value;
    }

    // als er geen chapters zijn geselecteerd, voer dan de normale flow uit
    if (this.activeChapters.length === 0) {
      this.videos = this.videosOriginal.slice();

      if (keyword != '') {
        this.storedKeyword = keyword;
        // this.paginationConfig.currentPage = 1;
        this.videos = this.collectionManager.search(keyword, this.videosOriginal).slice();
      }
      // als er wel chapters zijn geselecteerd, reset dan de originele array gefilterd op huidige chapters
    } else {
      this.videos = this.filteredBlockQuotes.slice();

      if (keyword != '') {
        this.storedKeyword = keyword;
        // this.paginationConfig.currentPage = 1;
        this.videos = this.collectionManager.search(keyword, this.filteredBlockQuotes).slice();
      }
    }
  }

  setVideos() {
    this.chapters = [];

    if (this.videosOriginal != null) {
      for (let i = 0; i < this.videosOriginal.length; i++) {
        if (this.videosOriginal[i]['chapters'].length > 0) {
          let chapter = this.videosOriginal[i]['chapters'][0]['chapter'];

          let index = this.chapters.findIndex(object => object['id'] === chapter['id']);

          if (index === -1) {
            this.chapters.push(chapter);
          }
        }
      }
    }
  }

  visitLesson(chapterLessonId, studyLevel) {
    let self = this;
    this.ngxLoader.start();
    this.apiConnectionService.read('lessons/visitVideo/' + chapterLessonId + '/' + studyLevel + '').then((response) => {
      window.open(response['link'], "_blank");
    }).catch(function (error) {
      self.ngxLoader.stop();
    });
  }

  filterBlockQuotes() {
    this.videos = this.videosOriginal.slice();
    this.videos = this.videos.filter(blockQuote => this.activeChapters.indexOf(blockQuote['chapters'][0]['chapter']['id']) !== -1);
    this.filteredBlockQuotes = this.videos.filter(blockQuote => this.activeChapters.indexOf(blockQuote['chapters'][0]['chapter']['id']) !== -1);

    if (this.storedKeyword !== '') {
      this.search(false);
    }

    if (this.activeChapters.length === 0) {
      this.filteredBlockQuotes = this.videosOriginal.slice();
      this.videos = this.videosOriginal.slice();
    }
  }

  addFilterValue(chapterId) {
    let indexOf = this.activeChapters.indexOf(chapterId);
    if (indexOf === -1) {
      this.activeChapters.push(chapterId);
    } else {
      this.activeChapters.splice(indexOf, 1);
    }
    this.filterBlockQuotes();
  }
}

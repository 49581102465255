<h1>
	Vakken
	<button routerLink="/section/form/0" class="btn btn-sm btn-primary">Toevoegen</button>
</h1>

<div class="table-search mb-1">
	<input (keyup)="search($event, sectionsOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th width="10">#</th>
      <th class="" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', sections)" scope="col">Vak</th>
      <th class="" scope="col" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', sections)" width="100">Status</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td class="bg-light" colspan="4"><em>Bovenaan</em></td>
    </tr>
    <tr *ngFor="let section of getHomeTop() | paginate: paginationConfig; let i = index">
<td>
    {{section['position']}}
  </td>
		<td><a routerLink="/chapters/{{section.id}}">{{section.name}}</a></td>
		<td>
			<div *ngIf="section.active==1">Actief</div>
			<div *ngIf="section.active==0">Niet actief</div>
		</td>
	    <td>
	    	<a routerLink="/section/form/{{section.id}}" class="edit-link"><i class="fas fa-edit"></i></a>  &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(section)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>

 <tr>
     <td class="bg-light" colspan="4"><em>Onderaan</em></td>
   </tr>
      <tr *ngFor="let section of getHomeBottom() let i = index">
<td>
    {{section['position']}}
  </td>
      <td><a routerLink="/chapters/{{section.id}}">{{section.name}}</a></td>
      <td>
        <div *ngIf="section.active==1">Actief</div>
        <div *ngIf="section.active==0">Niet actief</div>
      </td>
        <td>
          <a routerLink="/section/form/{{section.id}}" class="edit-link"><i class="fas fa-edit"></i></a>  &nbsp;
          <span class="delete-link" (click)="confirmDelete(section)"><i class="fas fa-trash"></i></span>
        </td>
      </tr>
    </tbody>

</table>


<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>

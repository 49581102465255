<ng-container *ngIf="isAdmin">
    <h1>
        Inactieve gebruikers
    </h1>
    <p><i>Deze gebruikers worden aankomende nacht verwijderd</i></p>
    
    <table class="table">
      <thead class="thead-dark">
        <tr>
          <th width="10">#</th>
          <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', users)">Gebruiker</th>
          <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['organisationName']['direction'] == 'asc', 'sort-desc': sortColumns['organisationName']['direction'] == 'desc'}"  (click)="sort('organisationName',users)">School</th>
          <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['deleteAfterMonths']['direction'] == 'asc', 'sort-desc': sortColumns['deleteAfterMonths']['direction'] == 'desc'}"  (click)="sort('deleteAfterMonths',users)">Maanden tot verwijderen</th>
          <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['lastLogin']['direction'] == 'asc', 'sort-desc': sortColumns['lastLogin']['direction'] == 'desc'}"  (click)="sort('lastLogin',users)">Laatst ingelogd</th>
          <th scope="col" width="100">Acties</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let user of users; let i = index" [ngClass]="{ 'd-none': user.hidden == true }">
            <td>{{ users.indexOf(user) + 1 }}</td>
            <td><a routerLink="/user/form/{{user.id}}">{{user.email}}</a></td>
            <td>
                {{user.organisationName}} <span *ngIf="user.organisationLocation">({{user.organisationLocation}})</span>
            </td>
            <td>
                {{user.deleteAfterMonths}}
            </td>
            <td>
                {{user.lastLogin}}
            </td>
            <td>
                <a routerLink="/user/form/{{user.id}}"><i class="fas fa-edit"></i></a>  &nbsp;
                <span class="delete-link" (click)="confirmDelete(user)"><i class="fas fa-trash"></i></span>
            </td>
        </tr>
      </tbody>
    </table>
    
    
    </ng-container>
    <ng-container *ngIf="!isAdmin">
        <p>Je hebt geen toegang tot deze pagina.</p>
    </ng-container>
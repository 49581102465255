<div id="head">
	<h1>
		<span routerLink="/chapters/{{item['sectionId']}}" class="btn btn-info mr-2"><i
				class="fas fa-arrow-left"></i></span>
		<span *ngIf="item.id">Module wijzigen</span>
		<span *ngIf="!item['id']">Module toevoegen</span>
	</h1>
	<div *ngIf="tabs['terms']">
		<div class="dropdown">

			<select class="form-control btn-secondary" (change)="exportTerms($event)">
				<option value="">Exporteren</option>
				<option *ngFor="let level of studyLevels" value="{{ level['value'] }}">{{ level['value']
					}}</option>
			</select>
		</div>
	</div>
</div>

<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

<section class="tab-buttons mt-3">
	<nav class="nav nav-pills nav-justified">
		<span class="nav-link" (click)="changeTab('info')"
			[ngClass]="{'active' : tabs['info'] == true}">Omschrijving</span>
		<span class="nav-link" (click)="changeTab('terms')"
			[ngClass]="{'active' : tabs['terms'] == true}">Begrippen</span>
		<span class="nav-link" (click)="changeTab('lessons')"
			[ngClass]="{'active' : tabs['lessons'] == true}">Lessen</span>
	</nav>
</section>

<section class="chapter-tab-container mb-5 mt-5 ">
	<section class="chapter-info" *ngIf="tabs['info'] == true">
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Vak:</label>
			</div>
			<div class="col-md-10">
				<select name="role" [(ngModel)]="item.sectionId" class="form-control">
					<option value="{{section['id']}}" [selected]="item['sectionId'] == section['id']"
						*ngFor="let section of sections">
						{{section['name']}}
					</option>
				</select>
				<div class="error" *ngIf="errors.sectionId">{{errors['sectionId']}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Naam:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.name }" id="name"
					required [(ngModel)]="item.name" name="name">
				<div class="error" *ngIf="errors.name">{{errors.name}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Actief:</label>
			</div>
			<div class="col-md-10">
				<div class="custom-control custom-switch">
					<input type="checkbox" class="custom-control-input" id="active" name="active"
						[ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
					<label class="custom-control-label" for="active"></label>
				</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Slug:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.slug }" id="slug"
					required [(ngModel)]="item.slug" name="slug">
				<div class="error" *ngIf="errors.slug">{{errors.slug}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label for="title" class="col-form-label">Omschrijving</label>
			</div>
			<div class="col-md-10">
				<textarea *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.description }"
					[(ngModel)]="item.description" id="description" name="description" placeholder=""
					required>{{item['description']}}</textarea>
				<div class="error" *ngIf="errors.description">{{errors.description}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Achtergrondkleur:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control"
					[ngClass]="{ 'is-invalid': errors.backgroundColor }" id="backgroundColor" required
					[(ngModel)]="item.backgroundColor" name="backgroundColor">
				<div class="error" *ngIf="errors.backgroundColor">{{errors.backgroundColor}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Type:</label>
			</div>
			<div class="col-md-10">
				<select name="role" [(ngModel)]="item.layout" class="form-control">
					<option value="{{layout['key']}}" [selected]="item['layout'] == layout['key']"
						*ngFor="let layout of layouts">
						{{layout['value']}}
					</option>
				</select>
				<div class="error" *ngIf="errors.layout">{{errors['layout']}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Stijl:</label>
			</div>
			<div class="col-md-10">
				<select name="role" [(ngModel)]="item.style" class="form-control">
					<option value="{{style['key']}}" [selected]="item['style'] == style['key']"
						*ngFor="let style of styles">
						{{style['value']}}
					</option>
				</select>
				<div class="error" *ngIf="errors.style">{{errors['style']}}</div>
			</div>
		</div>

		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Positie:</label>
			</div>
			<div class="col-md-10">
				<input type="text" *ngIf="item" class="form-control" id="position" required [(ngModel)]="item.position"
					name="position">
				<div class="error" *ngIf="errors.slug">{{errors.position}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-md-2">
				<label class="col-form-label">Afbeeldingen: <small>1. Voorpagina, 2. Inhoudsopgave</small></label>
			</div>
			<div class="col-md-3">
				<div class="upload-image-container">
					<img [src]="item.thumb" style="max-width:206px;" *ngIf="thumbSource == ''" />
					<img [src]="thumbSource" style="max-width:206px;" *ngIf="thumbSource != ''" />
				</div>
				<div class="upload-image-button">
					<input name="image" type="file" id="thumb-select" accept="image/*"
						(change)="handleThumbChange($event)" />
					<button type="button" class="btn btn-sm btn-secondary text-small"
						(click)="triggerThumbSelect($event)">Selecteer voorpagina-afbeelding</button>
				</div>
			</div>
			<div class="col-md-3">
				<div class="upload-image-container">
					<img [src]="item.image" style="max-width:206px;width:200px;" *ngIf="imageSource == ''" />
					<img [src]="imageSource" style="max-width:206px;width:200px;" *ngIf="imageSource != ''" />
				</div>
				<div class="upload-image-button">
					<input name="image" type="file" id="image-select" accept="image/*"
						(change)="handleImageChange($event)" />
					<button type="button" class="btn btn-sm btn-secondary text-small"
						(click)="triggerImageSelect($event)">Selecteer inhoudsopgave-afbeelding</button>
				</div>
			</div>
			<div class="col-md-3">
				<div class="upload-image-container">
					<img [src]="item.cover" style="max-width:206px;width:200px;" *ngIf="coverSource == ''" />
					<img [src]="coverSource" style="max-width:206px;width:200px;" *ngIf="coverSource != ''" />
				</div>
				<div class="upload-image-button">
					<input name="image" type="file" id="cover-select" accept="image/*"
						(change)="handleCoverChange($event)" />
					<button type="button" class="btn btn-sm btn-secondary text-small"
						(click)="triggerCoverSelect($event)">Selecteer cover-afbeelding</button>
				</div>
			</div>
		</div>

		<div class="form-group row mt-4">
			<div class="col-md-8">
				<button (click)="save()" class="btn btn-primary">
					<span *ngIf="!item.id">Toevoegen</span>
					<span *ngIf="item.id">Opslaan</span>
				</button>
			</div>
		</div>
	</section>
	<section class="chapter-terms" *ngIf="tabs['terms'] == true">
		<div class="row" *ngIf="!item.id">
			<div class="col-12">Termen kunnen na opslaan worden toegevoegd.</div>
		</div>
		<div class="row" *ngIf="item.id">
			<div class="col-8">
				<input type="text" class="form-control mb-3" [(ngModel)]="termKeyword" placeholder="Zoeken in termen" />
			</div>
			<div class="col-4">
				<select class="form-control" id="termCategory" required [(ngModel)]="termCategory" name="termCategory">
					<option *ngFor="let category of termCategories" value="{{ category['key'] }}">{{ category['value']
						}}</option>
				</select>
			</div>
			<div class="col-12">
				<section class="row-list-striped">
					<div class="form-group row" *ngFor="let term of termList()">
						<div class="col-4 pt-2">
							{{term.value}}
						</div>
						<div class="col-4 pt-2">
							<span *ngIf="term.categories">{{term.categories.join(', ')}}</span>
						</div>
						<div class="col-4 text-right pb-2">
							<div *ngFor="let studyLevel of studyLevels">
								<div class="float-right pl-5">
									<div class="custom-control custom-switch mt-2 float-left">
										<input type="checkbox" [checked]="checkTermChecked(term.id,studyLevel.value)"
											(change)="updateTerm(term.id,studyLevel.value, $event)"
											class="custom-control-input" id="term_{{studyLevel.value}}_{{term.id}}"
											name="term_{{studyLevel.value}}_{{term.id}}">
										<label class="custom-control-label"
											for="term_{{studyLevel.value}}_{{term.id}}"></label>
									</div>
									<span class="pt-2 d-inline-block pl-2">{{studyLevel.value}}</span>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
		<div class="form-group row mt-4">
			<div class="col-md-8">
				<button (click)="save()" class="btn btn-primary">
					<span *ngIf="!item.id">Toevoegen</span>
					<span *ngIf="item.id">Opslaan</span>
				</button>
			</div>
		</div>
	</section>
	<section class="chapter-lessons" *ngIf="tabs['lessons'] == true">
		<div class="row" *ngIf="!item.id">
			<div class="col-12">Lessen kunnen na opslaan worden toegevoegd.</div>
		</div>
		<div class="row" *ngIf="item.id">
			<div class="col-9">
				<section class="row-list-striped" cdkDropList (cdkDropListDropped)="onDrop($event)">
					<div class="row pt-3 pb-3"
						[ngClass]="{'border-left border-info': lesson['type'] === 'CHOICE_ASSIGNMENT'}"
						*ngFor="let lesson of item['lessons']" cdkDrag>
						<div class="col-1">
							<span class="drag-handle" cdkDragHandle><i class="fas fa-arrows-alt"></i></span>
						</div>
						<div class="col-8">{{lesson.name}} <span class="label" *ngIf="lesson.free == 1">GRATIS</span>
						</div>
						<div class="col-3 text-right">
							<span (click)="syncLesson(lesson)" class="edit-link"><i class="fas fa-sync"></i></span>
							<a routerLink="/lesson/form/{{lesson.lessonId}}" class="d-inline-block ml-3"><i
									class="fas fa-edit"></i></a>
							<span class="delete-link d-inline-block ml-3" (click)="confirmDeleteLesson(lesson)"><i
									class="fas fa-trash"></i></span>
						</div>
					</div>
				</section>
			</div>
			<div class="col-3">
				<section class="lesson-container">
					<h3>Lessen <button type="button" class="float-right btn btn-primary"
							routerLink="/lesson/form/0">Toevoegen</button></h3>
					<div class="form-group row">
						<div class="col-md-2">
							<div class="custom-control custom-switch mt-2">
								<input type="checkbox" class="custom-control-input" id="onlyOrphanLessons"
									name="onlyOrphanLessons" [ngModelOptions]="{standalone: true}"
									[(ngModel)]="onlyOrphanLessons">
								<label class="custom-control-label" for="onlyOrphanLessons"></label>
							</div>
						</div>
						<div class="col-md-10">
							<label class="col-form-label" style="text-align:left ! important;">Alleen
								ongekoppeld</label>
						</div>
					</div>
					<button type="button" class="float-right btn btn-secondary mb-5 w-100" (click)="fullSync()"><i
							class="fas fa-sync"></i> Volledige sync</button>
					<input type="text" class="form-control mb-3" [(ngModel)]="lessonKeyword"
						placeholder="Zoeken in lessen" />
					<div class="lesson-rows">
						<div class="lesson-row" *ngFor="let lesson of lessons">
							<div class="lesson-row-content clearfix clear-fix" *ngIf="isVisible(lesson)">
								<button type="button" (click)="addLesson(lesson)"
									class="float-left btn btn-default add-lesson pt-1 pl-2"><i
										class="fas fa-plus"></i></button>
								<span>
									<a routerLink="/lesson/form/{{lesson.id}}">{{lesson.name}} ({{lesson.typeText}})
										<span class="" *ngIf="lesson['free']">Gratis</span></a>
									<span style="overflow:hidden;"
										*ngFor="let chapter of lesson['chapters']; let isLast=last"
										class="d-block text-muted">
										<small>{{chapter['name']}} <i
												*ngIf="chapter['sectionName']">({{chapter['sectionName']}})</i></small>{{isLast
										? '' : ', '}}
									</span>
								</span>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</section>
</section>
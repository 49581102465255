<div class="navbar navbar-light bg-light">
	<a href="/index" class="navbar-brand font-weight-bold">{{ SYSTEM_NAME }}</a>

	<div *ngIf="currentUser">

		<ng-container *ngIf="currentUser['role'] == 'ADMIN'">
			<button type="button" routerLink="/schools" class="d-inline btn btn-light mr-2">
				Scholen
			</button>

			<button type="button" routerLink="/users" class="d-inline btn btn-light mr-2">
				Gebruikers
			</button>

			<button type="button" routerLink="/activitylog" class="d-inline btn btn-light mr-2">
				Logboek
			</button>

			&nbsp; &nbsp; &nbsp;
		</ng-container> 

		<ng-container *ngIf="currentUser['role'] == 'ADMIN' || currentUser['role'] == 'EDITOR'">
			<button type="button" routerLink="/banners" class="d-inline btn btn-light mr-2">
				Banners
			</button>

			<button type="button" routerLink="/sections" class="d-inline btn btn-light mr-2">
				Vakken
			</button>

			<button type="button" routerLink="/chapters" class="d-inline btn btn-light mr-2">
				Modules
			</button>

			<button type="button" routerLink="/lessons" class="d-inline btn btn-light mr-2">
				Lessen
			</button>

			<button type="button" routerLink="/feedback" class="d-inline btn btn-light mr-2">
				Feedback <span class="badge badge-info" *ngIf="unreadFeedback > 0">{{unreadFeedback}}</span>
			</button>

			<button type="button" routerLink="/examinations" class="d-inline btn btn-light mr-2">
				Toetsen
			</button>

			<button type="button" routerLink="/pages" class="d-inline btn btn-light mr-2">
				Pagina's
			</button>
		</ng-container>

		&nbsp; &nbsp; &nbsp;

		<button type="button" (click)="logout()" class="d-inline btn btn-light mr-2">
			Uitloggen
		</button>

	</div>

	<button type="button" routerLink="/login" class="d-inline btn btn-lg btn-light mr-2" *ngIf="currentUser == false">
		Inloggen
	</button>

</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-12 p-5">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>

<ngx-ui-loader></ngx-ui-loader>
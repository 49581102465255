<h1>
	Lessen
	<button routerLink="/lesson/form/0" href="" class="btn btn-sm btn-primary">Toevoegen</button>
</h1>
<div class="table-search mb-1">
	<input (keyup)="search($event, lessonsOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', lessons)" scope="col">Les</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('free', lessons)" scope="col" width="100">Gratis</th>
      <th class="sortable" scope="col" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', lessons)" width="100">Status</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let lesson of lessons | paginate: paginationConfig; let i = index" [ngClass]="{ 'd-none': lesson.hidden == true }">
		<td><a routerLink="/lesson/form/{{lesson.id}}">{{lesson.name}} <small *ngIf="lesson.type != 'LESSON'">[{{lesson.type}}]</small></a>																	<small><em class="d-block">{{lesson.description}}</em></small>
	<span style="overflow:hidden;" *ngFor="let chapter of lesson['chapters']; let isLast=last" class="d-block text-muted">
										<small>{{chapter['name']}}<i *ngIf="chapter['sectionName']"> ({{chapter['sectionName']}})</i></small>
									{{isLast ? '' : ', '}}
									</span>


									</td>
		<td>
			<div *ngIf="lesson.free==1">Gratis</div>
			<div *ngIf="lesson.free==0">Betaald</div>
		</td>
		<td>
			<div *ngIf="lesson.active==1">Actief</div>
			<div *ngIf="lesson.active==0">Niet actief</div>
		</td>
	    <td>
	    	<span (click)="syncLesson(lesson)" class="edit-link"><i class="fas fa-sync"></i></span> &nbsp;
	    	<a routerLink="/lesson/form/{{lesson.id}}" class="edit-link"><i class="fas fa-edit"></i></a> &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(lesson)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>
  </tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>

<h1>Feedback</h1>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col" width="200">Datum</th>
      <th scope="col">Feedback</th>
      <th scope="col">Gebruiker</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
 <tr>
   <td class="bg-light" colspan="4"><em>Lopend</em></td>
 </tr>
  <tr *ngFor="let feedback of getActiveItems() let i = index">
    <td>{{feedback.dateTimeCreated | date:'short'}}</td>
    <td><span *ngFor="let chapter of feedback['chapters'] let i = index" class="font-weight-bold"><a class="go-link" (click)="visitLessonFeedBack(feedback['id'],chapter['id'])">{{feedback['lesson']['name']}} ({{chapter['chapter']['name']}})</a></span> <br /> {{feedback.content}}</td>
    <td>{{feedback.email}} <small>{{ feedback['lessonContent'] != null ? feedback['lessonContent']['studyLevel'] : '' }}</small></td>
    <td class="text-center">
      <span class="archive-link" (click)="archive(feedback)"><i class="fas fa-archive"></i></span>  &nbsp;
      <span class="delete-link" (click)="confirmDelete(feedback)"><i class="fas fa-trash"></i></span>
    </td>
  </tr>
<tr>
  <td class="bg-light" colspan="4"><em>Archief</em></td>
</tr>
  <tr class="text-muted" *ngFor="let feedback of getArchiveItems() let i = index">
    <td>{{feedback.dateTimeCreated | date:'short'}}</td>
    <td><span *ngFor="let chapter of feedback['chapters'] let i = index" class="font-weight-bold" style="text-decoration:underline;"><a (click)="visitLessonFeedBack(feedback['id'],chapter['id'])">
    {{chapter['chapter']['name']}} / {{feedback['lesson']['name']}}
    </a></span> <br /> {{feedback.content}}</td>
    <td>{{feedback.email}} <small>{{ feedback['lessonContent'] != null ? feedback['lessonContent']['studyLevel'] : '' }}</small></td>
    <td class="text-center">
      <span class="archive-link" (click)="dearchive(feedback)"><i class="fas fa-archive"></i></span>  &nbsp;
      <span class="delete-link" (click)="confirmDelete(feedback)"><i class="fas fa-trash"></i></span>
    </td>
  </tr>
  </tbody>
</table>

import { Component, OnInit } from '@angular/core';
import { AuthenticationService }  from '../../services/core/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notauthorized',
  templateUrl: './notauthorized.component.html',
  styleUrls: ['./notauthorized.component.scss']
})
export class NotauthorizedComponent implements OnInit {

	constructor(
		public authenticationService: AuthenticationService,
		public router: Router
	) { 
	}

  ngOnInit(): void {
	let auth = this.authenticationService.isAuthenticated();
	if (auth == true) {
		this.router.navigate(['index']);
	}
  }

}

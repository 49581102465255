<h1>
	Toetsen 
	<button routerLink="/examination/form/0" href="" class="btn btn-sm btn-primary">Toevoegen</button>
</h1>
<div class="table-search mb-1">
	<input (keyup)="search($event, examinationsOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['title']['direction'] == 'asc', 'sort-desc': sortColumns['title']['direction'] == 'desc'}" (click)="sort('title', examinations)" scope="col">Titel</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['variant']['direction'] == 'asc', 'sort-desc': sortColumns['variant']['direction'] == 'desc'}" (click)="sort('variant', examinations)" scope="col">Variant</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['section']['direction'] == 'asc', 'sort-desc': sortColumns['section']['direction'] == 'desc'}" (click)="sort('section', examinations)" scope="col">Vak</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['createdAt']['direction'] == 'asc', 'sort-desc': sortColumns['createdAt']['direction'] == 'desc'}" (click)="sort('createdAt', examinations)" scope="col">Gemaakt op</th>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['updatedAt']['direction'] == 'asc', 'sort-desc': sortColumns['updatedAt']['direction'] == 'desc'}" (click)="sort('updatedAt', examinations)" scope="col">Geupdatet op</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let examination of examinations | paginate: paginationConfig; let i = index">
      <td><a routerLink="/examination/form/{{ examination.id }}">{{ examination.title }}</a></td>
      <td><a routerLink="/examination/form/{{ examination.id }}">{{ examination.variant }}</a></td>
      <td>{{ examination.sectionName }}</td>
      <td>{{ examination.createdAt }}</td>
      <td>{{ examination.updatedAt }}</td>
	    <td>
	    	<a routerLink="/examination/form/{{ examination.id }}"><i class="fas fa-edit"></i></a> &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(examination)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>
  </tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
<ng-container *ngIf="isAdmin">

<h1>
	<span (click)="backLink()" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span>Antwoorden van <span *ngIf="user">{{user.name}} {{user.email}}</span></span>
</h1>

<section class="user-answers">
<div class="table-search mb-1">
    <input (keyup)="search($event, answersOriginal)">
</div>
<table class="table">
    <thead class="thead-dark">
      <tr>
        <th width="10">#</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['questionId']['direction'] == 'asc', 'sort-desc': sortColumns['questionId']['direction'] == 'desc'}" (click)="sort('questionId', answers)" scope="col">Question ID</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['lessonTitle']['direction'] == 'asc', 'sort-desc': sortColumns['lessonTitle']['direction'] == 'desc'}" (click)="sort('lessonTitle', answers)" scope="col">Les</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['questionTitle']['direction'] == 'asc', 'sort-desc': sortColumns['questionTitle']['direction'] == 'desc'}" (click)="sort('questionTitle', answers)" scope="col">Vraag</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['value']['direction'] == 'asc', 'sort-desc': sortColumns['value']['direction'] == 'desc'}" (click)="sort('value', answers)" scope="col">Antwoord</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['createdAt']['direction'] == 'asc', 'sort-desc': sortColumns['createdAt']['direction'] == 'desc'}" (click)="sort('createdAt', answers)" scope="col">Aangemaakt</th>
        <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['updatedAt']['direction'] == 'asc', 'sort-desc': sortColumns['updatedAt']['direction'] == 'desc'}" (click)="sort('updatedAt', answers)" scope="col">Gewijzigd</th> 
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let answer of answers; let i = index">
          <td>{{ answers.indexOf(answer) + 1 }}</td>
          <td>{{ answer['questionId'] }}</td>
          <td>{{ answer['lessonTitle'] }}</td>
          <td>{{ answer['questionTitle'] }}</td>
          <td>{{ answer['value'] }}</td>
          <td>{{ answer['createdAt'] }}</td>
          <td>{{ answer['updatedAt'] }}</td>
      </tr>
    </tbody>
  </table>
  
</section>
</ng-container>
<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>
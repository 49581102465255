<h1>
	<span routerLink="/sections" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Vak toevoegen</span>
	<span *ngIf="item.id">Vak wijzigen</span>
</h1>


<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

	<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">
	<br/><br/>
	Naam:
	<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
	<div class="error" *ngIf="errors.name">{{errors.name}}</div>
	
	<br/><br/>
	Slug:
	<input type="text" *ngIf="item" class="form-control" id="slug" required [(ngModel)]="item.slug" name="slug">
	   <div class="error" *ngIf="errors.slug">{{errors.slug}}</div>
	<br/><br/>

	Korte naam:
	<input type="text" *ngIf="item" class="form-control" id="shortName" required [(ngModel)]="item.shortName" name="shortName">
	<div class="error" *ngIf="errors.shortName">{{errors.shortName}}</div>
	
	<br/><br/>
	Kleur:
	<input type="text" *ngIf="item" class="form-control" id="color" required [(ngModel)]="item.color" name="color">
	<div class="error" *ngIf="errors.color">{{errors.color}}</div>
	
	<br/><br/>
	Position:
	<input type="text" *ngIf="item" class="form-control" id="position" required [(ngModel)]="item.position" name="position">
	   <div class="error" *ngIf="errors.position">{{errors.position}}</div>
	<br/><br/>
	
	Actief:<br/>
	<input type="radio" *ngIf="item" id="active_1" [value]="1" [(ngModel)]="item.active" name="active"> Actief<br/>
	<input type="radio" *ngIf="item" id="active_inactive" [value]="0" [(ngModel)]="item.active" name="active"> Niet actief <br/>

	
	<div class="mb-4 mt-3">Afbeelding:<br/>
		<div class="upload-image-container">
			<img [src]="item.image" style="max-width:206px;width:210px;" *ngIf="imageSource == ''"/>
		   <img [src]="imageSource" style="max-width:206px;width:210px;" *ngIf="imageSource != ''"/>
	   </div>
	   <div class="upload-image-button">
		   <input name="image" type="file" id="image-select" accept="image/*" (change)="handleImageChange($event)" />
		   <button type="button" class="btn btn-sm btn-secondary text-small" (click)="triggerImageSelect($event)">Selecteer afbeelding</button>
	   </div>
	</div>
	<div class="alert alert-danger" *ngIf="errorposition">Selecteer voorpagina boven of onder.</div>
	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Voorpagina boven:</label>
		</div>
		<div class="col-md-10">
			<div class="custom-control custom-switch">
			  <input type="checkbox" class="custom-control-input" id="homeTop" name="homeTop" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.homeTop">
			  <label class="custom-control-label" for="homeTop"></label>
			</div>
		</div>
	</div>
	
	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Voorpagina onder:</label>
		</div>
		<div class="col-md-10">
			<div class="custom-control custom-switch">
			  <input type="checkbox" class="custom-control-input" id="homeBottom" name="homeBottom" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.homeBottom">
			  <label class="custom-control-label" for="homeBottom"></label>
			</div>
		</div>
	</div>
			
	<br/><br/>
	<button (click)="save()" class="btn btn-primary">
		<span *ngIf="!item.id">Toevoegen</span>
		<span *ngIf="item.id">Opslaan</span>
	</button>
        
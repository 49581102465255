<h1>
	Pagina's
	<button routerLink="/page/form/0" href="" class="btn btn-sm btn-primary">Toevoegen</button>
</h1>
<div class="table-search mb-1">
	<input (keyup)="search($event, pagesOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr>
      <th class="sortable" [ngClass]="{ 'sort-asc': sortColumns['title']['direction'] == 'asc', 'sort-desc': sortColumns['title']['direction'] == 'desc'}" (click)="sort('title', pages)" scope="col">Pagina</th>
      <th class="sortable" scope="col" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}" (click)="sort('active', pages)" width="100">Status</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let page of pages | paginate: paginationConfig; let i = index" [ngClass]="{ 'd-none': page.hidden == true }">
		<td><a routerLink="/page/form/{{page.id}}">{{page.title}}</a></td>
		<td>
			<div *ngIf="page.active==1">Actief</div>
			<div *ngIf="page.active==0">Niet actief</div>
		</td>
	    <td>
	    	<a routerLink="/page/form/{{page.id}}" class="edit-link"><i class="fas fa-edit"></i></a>  &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(page)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>
  </tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>

import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ActivitiesService } from 'src/app/services/activities.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

import { ActivatedRoute } from '@angular/router';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-activity-log-details',
  templateUrl: './activity-log-details.component.html',
  styleUrls: ['./activity-log-details.component.scss']
})
export class ActivityLogDetailsComponent implements OnInit {
  
  activitiesOriginal: any = [];
  activityLogs: any = [];
  sortColumns = {
    'date': { type: 'string', direction: '' },
    'user': { type: 'string', direction: '' },
    'type': { type: 'string', direction: '' },
    'modifiedUsername': { type: 'string', direction: '' },
    'responsibleUsername': { type: 'string', direction: '' },
    'description': { type: 'string', direction: '' }
  };

  paginationConfig: PaginationConfig = new PaginationConfig();

  constructor(
    public activitiesService: ActivitiesService,
    public collectionManager: CollectionManagerService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  isAdmin: boolean;

  ngOnInit(): void {
    this.authenticationService.isCurrentUserAdmin().then((data) => {
      this.isAdmin = data;
    });
    this.all();
  }

  userId: any;

  all () {
    let self = this;
    this.ngxLoader.start();

    this.userId = this.route.snapshot.paramMap.get('id');

	this.activitiesService.read('ActivityLog/user/' + this.userId).then((response) => {
	  self.activitiesOriginal = response;
      self.activityLogs = response;
      self.ngxLoader.stop();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
	let keyword = event.target.value;
	this.activityLogs = this.activitiesOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.activityLogs = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }

  convertTypeToHuman(str) {
    if(str == "PASSWORD_MODIFIED") {
      return "wachtwoord gewijzigd";
    } else if(str == "PROFILE_MODIFIED") {
      return "profiel gewijzigd";
    } else {
      return str;
    }
  }
}

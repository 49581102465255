<h1>
	<span routerLink="/examinations" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Toets toevoegen</span>
	<span *ngIf="item.id">Toets wijzigen</span>
</h1>

<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkele fouten gevonden.</div>

<section class="user-form mt-3" *ngIf="item">
	<input type="hidden" class="form-control" id="id" [(ngModel)]="item.id" name="id">

    <div class="form-group row">
        <div class="col-md-2">
			<label class="col-form-label">Vak:</label>	
		</div>
        <div class="col-md-10">
            <select [disabled]="isDisabled" class="form-control" id="sectionId" required [(ngModel)]="item.sectionId"  name="sectionId" (change)="setChaptersOnSectionChange($event)">
                <option *ngFor="let section of sections" value="{{ section.id }}">{{ section.name }}</option>
            </select>
            <div class="error" *ngIf="errors.sectionId">{{ errors.sectionId }}</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
			<label class="col-form-label">Hoofdstuk:</label>	
		</div>
        <div class="col-md-10">
            <select [disabled]="isDisabled" class="form-control" id="chapterId" required [(ngModel)]="item.chapterId" name="chapterId">
                <option *ngFor="let chapter of chapters" value="{{ chapter.id }}">{{ chapter.name }}</option>
            </select>
            <div class="error" *ngIf="errors.chapterId">{{ errors.chapterId }}</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
			<label class="col-form-label">Variant:</label>	
		</div>
        <div class="col-md-10">            
            <input type="text" [disabled]="isDisabled" class="form-control" id="variant" required [(ngModel)]="item.variant" name="variant">
            <div class="error" *ngIf="errors.variant">{{ errors.variant }}</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
			<label class="col-form-label">Actief:</label>	
		</div>
        <div class="col-md-10">            
            <input type="radio" id="active_1" [value]="1" required [(ngModel)]="item.active" name="active"> Actief<br/>
	        <input type="radio" id="active_inactive" [value]="0" [(ngModel)]="item.active" name="active"> Niet actief <br/>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
			<label class="col-form-label">Niveau:</label>	
		</div>
        <div class="col-md-10">            
            <select [disabled]="isDisabled" class="form-control" id="studyLevel" required [(ngModel)]="item.studyLevel" name="studyLevel">
                <option *ngFor="let studyLevel of studyLevels" value="{{ studyLevel.value }}">{{ studyLevel.name }}</option>
            </select>
            <div class="error" *ngIf="errors.studyLevel">{{ errors.studyLevel }}</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
            <label class="col-form-label">Toets (bestand):</label>
        </div>
        <div class="col-md-10" *ngIf="item.id">
            <div *ngIf="contentUploadFileInProgress==true">
                <img src="/assets/images/loader.gif" alt="Laden ..."/>
            </div>
            <div class="row" *ngIf="!contentUploadFileInProgress">
                <div class="col-md-3">
                    <input type="file" id="contentFile" (change)="uploadContentFile($event.target.files)">
                </div>
                <div class="col-md-9">
                    <span>Huidig:  </span>
                    <a href="javascript:;" (click)="requestDownload(item['contentFileId']);" >{{ item.contentFile }}</a>
                </div>
                <div class="error" *ngIf="errors.contentFile">{{errors.contentFile}}</div>
            </div>
        </div>
        <div class="col-md-10" *ngIf="!item.id">
            <div class="text-muted p-3">Uploaden van het toets bestand is pas mogelijk na opslaan.</div>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-2">
            <label class="col-form-label">Antwoordblad (bestand):</label>	
        </div>
        <div class="col-md-10" *ngIf="item.id">
            <div *ngIf="answerUploadFileInProgress==true">
                <img src="/assets/images/loader.gif" alt="Laden ..."/>
            </div>
            <div class="row" *ngIf="!answerUploadFileInProgress">
                <div class="col-md-3">
                    <input type="file" id="answerFile" (change)="uploadAnswerFile($event.target.files)">
                </div>
                <div class="col-md-9">
                    <span>Huidig:  </span>
                    <a href="javascript:;" (click)="requestDownload(item['answerFileId']);">{{ item.answerFile }}</a>
                </div>
                <div class="error" *ngIf="errors.answerFile">{{errors.answerFile}}</div>
            </div>
        </div>
        <div class="col-md-10" *ngIf="!item.id">
            <div class="text-muted p-3">Uploaden van het antwoordblad bestand is pas mogelijk na opslaan.</div>
        </div>
    </div>
			
	<button (click)="save()" class="btn btn-primary mb-4">
		<span *ngIf="!item.id">Toevoegen</span>
		<span *ngIf="item.id">Opslaan</span>
	</button>

    <div class="revisions" *ngIf="revisions.length > 0">
        <h3>Revisies</h3>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Bestand</th>
                    <th></th>
                    <th>Uploaddatum</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let revision of revisions">
                    <td>
                        <a href="javascript:;" (click)="requestDownload(revision['id']);" class="mr-5" target="_blank">{{ revision.filename }} </a>
                    </td>
                    <td>{{ (item.contentFile == revision.filename || item.answerFile == revision.filename) ? '(Huidig)' : '' }}</td>
                    <td>{{ revision.createdAt }}</td>
                    <td><span class="delete-link cancel-gdpr-upload-file" (click)="confirmDeleteFile(revision)"><i class="fas fa-trash"></i></span></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>
        
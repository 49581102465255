import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hasError'
})
export class HasErrorPipe implements PipeTransform {

  transform(value: any, key?: string): unknown {
	if (!value) {
		return false;
	}
    return key in value;
  }

}

import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LessonsService }  from '../../services/lessons.service';
import { SectionsService }  from '../../services/sections.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';
import { ModalContentComponent } from 'src/app/modals/modal-content/modal-content.component';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-lessons-index',
  templateUrl: './lessons-index.component.html',
  styleUrls: ['./lessons-index.component.scss']
})

export class LessonsIndexComponent implements OnInit {
	lessonsOriginal: any = [];
  lessons : any = [];
  sortColumns = {
  	'name' : { type: 'string', direction: ''},
  	'type' : { type: 'string', direction: ''}, 
  	'free' : { type: 'numeric', direction: ''}, 
  	'author' : { type: 'string', direction: ''},
  	'position' : { type: 'numeric', direction: ''},
  	'active' : { type: 'numeric', direction: ''}
  };
  section : any = {};
  modal;

  paginationConfig: PaginationConfig = new PaginationConfig();
  
  constructor(
	public apiConnectionService: ApiConnectionService,
  	public lessonsService: LessonsService,
  	public sectionsService: SectionsService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private route: ActivatedRoute,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
	this.all();
  }
  
  syncLesson (item)
  {
	  let self = this;

	  this.modal = this.modalService.open(ModalContentComponent, {
		  backdrop: 'static',
		  keyboard: false
	  });

	  this.apiConnectionService.read('lessons/syncSingle/'+item['id']+'',).then((response) => {
		  self.modal.componentInstance.message = '<i class="fas fa-check" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les gesynced!';
	  });    

	  this.modal.componentInstance.message = '<i class="fas fa-circle-notch fa-spin" style="font-size: 24px;float: left;display: block;margin-right: 30px;"></i> Les aan het syncen';
		  this.modal.componentInstance.title = 'Sync les';
		  this.modal.result.then((result) => {
	  });
  }

  all () {
  	let sectionId = this.route.snapshot.paramMap.get('id');
    let self = this;
    
    let url = 'lessons/all';
    
    this.ngxLoader.start();
	this.lessonsService.read(url).then((response) => {
	  self.lessonsOriginal = response;
      self.lessons = response;
      self.ngxLoader.stop();
	});
  }
  
  delete (lesson) {
    let self = this;
    this.ngxLoader.start();
	this.lessonsService.delete('lessons/delete/'+lesson.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  confirmDelete(lesson) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je '+lesson.name+' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(lesson);
		}
	});
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
	let keyword = event.target.value;
	this.lessons = this.lessonsOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.lessons = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
}

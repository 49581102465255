import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
      public authenticationService: AuthenticationService,
      private router: Router
    ) {}
  
    canActivate(): boolean {
      let auth = this.authenticationService.isAuthenticated();

      if (auth == false) {
      	this.router.navigate(['login']);
      	return false;
      } else {
      	return true;
      }
    }
}
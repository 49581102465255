import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CollectionManagerService {

  constructor() { }

  search (keyword, collection) {
	let results = [];

  	keyword = keyword.toLowerCase();
  
	for (let key in collection) {
		let searchString = JSON.stringify(collection[key]);
			searchString = searchString.toLowerCase();

		if (searchString.indexOf(keyword) !== -1) {
			results.push(collection[key]);
		} 
	}

	return results;
  }

  sortNumeric (key, collection, direction, type) {  
	collection.sort(function(a, b) {
		if (type === 'numeric') {
				return parseFloat(a[key]) - parseFloat(b[key]);
		} else {
			if (!a[key]) {
				return -1;
			}
		 
			if (!b[key]) {
				return +1;
			}
			return  a[key].localeCompare(b[key]);
	    }
	});
	if (direction === 'desc') {
		collection.reverse();
	}
  }
}
import { Injectable } from '@angular/core';
import { ApiConnectionService }  from '../services/core/api-connection.service';
import { ConfigService }  from '../services/core/config.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/core/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService extends ApiConnectionService {

  constructor(public http: HttpClient, public configService: ConfigService, 
		public authenticationService: AuthenticationService,  public router: Router) { 
  	super(http, configService, authenticationService, router);
  }
}

<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
	<button type="button" class="close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	{{message}}
</div>
<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link">Annuleren</button>
	<button (click)="confirm()" type="submit" class="btn btn-danger">Verwijderen</button>
</div>
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { IndexComponent } from './pages/index/index.component';

import { SchoolsIndexComponent } from './pages/schools-index/schools-index.component';
import { SchoolsFormComponent } from './pages/schools-form/schools-form.component';

import { SectionsIndexComponent } from './pages/sections-index/sections-index.component';
import { SectionsFormComponent } from './pages/sections-form/sections-form.component';

import { ChaptersIndexComponent } from './pages/chapters-index/chapters-index.component';
import { ChaptersFormComponent } from './pages/chapters-form/chapters-form.component';

import { UsersFormComponent } from './pages/users-form/users-form.component';
import { UsersIndexComponent } from './pages/users-index/users-index.component';

import { LessonsFormComponent } from './pages/lessons-form/lessons-form.component';
import { LessonsIndexComponent } from './pages/lessons-index/lessons-index.component';

import { PagesIndexComponent } from './pages/pages-index/pages-index.component';
import { PagesFormComponent } from './pages/pages-form/pages-form.component';

import { AuthGuard } from './services/core/authguard.service';

import { LoginComponent } from './pages/login/login.component';
import { NotauthorizedComponent } from './pages/notauthorized/notauthorized.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { LogsComponent } from './pages/logs/logs.component';

import { CheckQuotesComponent } from './pages/check-quotes/check-quotes.component';
import { CheckYoutubeComponent } from './pages/check-youtube/check-youtube.component';
import { CheckImagesComponent } from './pages/check-images/check-images.component';
import { ExaminationsFormComponent } from './pages/examinations-form/examinations-form.component';
import { ExaminationsIndexComponent } from './pages/examinations-index/examinations-index.component';
import { UsersAnswersComponent } from './pages/users-answers/users-answers.component';

import { ActivityLogComponent } from './pages/activity-log/activity-log.component';
import { ActivityLogDetailsComponent } from './pages/activity-log-details/activity-log-details.component';
import { BannersFormComponent } from './pages/banners-form/banners-form.component';

import { BannersComponent } from './pages/banners/banners.component';
import { UsersInactiveComponent } from './pages/users-inactive/users-inactive.component';

const routes: Routes = [
  { path: '', redirectTo: '/index', pathMatch: 'full', canActivate: [AuthGuard] },

  { path: 'index', component: IndexComponent, canActivate: [AuthGuard] },

  { path: 'schools', component: SchoolsIndexComponent, canActivate: [AuthGuard] },
  { path: 'school/form/:id', component: SchoolsFormComponent, canActivate: [AuthGuard] },
  { path: 'sections', component: SectionsIndexComponent, canActivate: [AuthGuard] },
  { path: 'section/form/:id', component: SectionsFormComponent, canActivate: [AuthGuard] },

  { path: 'chapters/:id', component: ChaptersIndexComponent, canActivate: [AuthGuard] },
  { path: 'chapters', component: ChaptersIndexComponent, canActivate: [AuthGuard] },
  { path: 'chapter/form/:id', component: ChaptersFormComponent, canActivate: [AuthGuard] },

  { path: 'lesson/form/:id', component: LessonsFormComponent, canActivate: [AuthGuard] },
  { path: 'lesson/form/:id/:chapterId', component: LessonsFormComponent, canActivate: [AuthGuard] },
  { path: 'lessons', component: LessonsIndexComponent, canActivate: [AuthGuard] },

  { path: 'examination/form/:id', component: ExaminationsFormComponent, canActivate: [AuthGuard] },
  { path: 'examinations', component: ExaminationsIndexComponent, canActivate: [AuthGuard] },

  { path: 'page/form/:id', component: PagesFormComponent, canActivate: [AuthGuard] },
  { path: 'pages', component: PagesIndexComponent, canActivate: [AuthGuard] },

  { path: 'users', component: UsersIndexComponent, canActivate: [AuthGuard] },
  { path: 'user/form/:id', component: UsersFormComponent, canActivate: [AuthGuard] },
  { path: 'user/answers/:id', component: UsersAnswersComponent, canActivate: [AuthGuard] },
  { path: 'user/inactive', component: UsersInactiveComponent, canActivate: [AuthGuard] },

  { path: 'notauthorized', component: NotauthorizedComponent },

  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: 'activitylog', component: ActivityLogComponent, canActivate: [AuthGuard] },
  { path: 'activitylog/:id', component: ActivityLogDetailsComponent, canActivate: [AuthGuard] },
  { path: 'check-images', component: CheckImagesComponent, canActivate: [AuthGuard] },
  { path: 'check-quotes', component: CheckQuotesComponent, canActivate: [AuthGuard] },
  { path: 'check-youtube', component: CheckYoutubeComponent, canActivate: [AuthGuard] },

  { path: 'banners', component: BannersComponent, canActivate: [AuthGuard] },
  { path: 'banners/form/:id', component: BannersFormComponent, canActivate: [AuthGuard] },

  { path: 'logs', component: LogsComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SectionsService } from '../../services/sections.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-sections-form',
	templateUrl: './sections-form.component.html',
	styleUrls: ['./sections-form.component.scss']
})
export class SectionsFormComponent implements OnInit {
	error: boolean = false;
	item: any = {};
	errors: any = [];
	mutateForm;
	errormessage = false;
	errorposition = false;
	successmessage = false;
	imageSource: string = '';

	constructor(
		public service: SectionsService,
		private route: ActivatedRoute,
		private ngxLoader: NgxUiLoaderService
	) { }

	ngOnInit(): void {
		this.fetchItem();
	}

	fetchItem() {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
		this.ngxLoader.start();
		this.service.read('sections/details/' + id + '').then((response) => {
			self.item = response;
			self.ngxLoader.stop();
		});
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		let postData = {};
		postData = self.item;

		if (this.imageSource != '') {
			postData['imageSource'] = this.imageSource;
		}

		if (this.item.homeTop == null && this.item.homeBottom == null) {
			self.errorposition = true;
		} else {
			this.service.save('sections/save', postData).then((response) => {
				self.item = response;
				self.ngxLoader.stop();
			}).catch(function (response) {
				self.errormessage = true;
				for (let error in response['error']['errors']) {
					self.errors[error] = response['error']['errors'][error];
				}
			});
		}
	}

	handleImageChange(e) {
		var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
		var pattern = /image-*/;
		var reader = new FileReader();
		if (!file.type.match(pattern)) {
			alert('invalid format');
			return;
		}
		reader.onload = this._handleImageReaderLoaded.bind(this);
		reader.readAsDataURL(file);
	}

	_handleImageReaderLoaded(e) {
		let reader = e.target;
		this.imageSource = reader.result;
	}

	triggerImageSelect(event) {
		let element: HTMLElement = document.getElementById('image-select') as HTMLElement;
		element.click();
	}
}

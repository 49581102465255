<ng-container *ngIf="isAdmin">
<h1>
	<span (click)="backLink()" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Gebruiker toevoegen</span>
	<span *ngIf="item.id">Gebruiker wijzigen</span>
</h1>

<section class="tab-buttons mt-3">
	<nav class="nav nav-pills nav-justified">
	  <span class="nav-link"  (click)="changeTab('account')" [ngClass]="{'active' : tab == 'account'}">Account</span>
	  <span class="nav-link" (click)="changeTab('logs')" [ngClass]="{'active' : tab == 'logs'}">Logs</span>
	</nav>
</section>

<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

<section class="user-form" *ngIf="item && tab == 'account'">
	<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">

	<div class="form-group">
		<label for="name">Naam</label>
		<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.name" name="name">
		<div class="error" *ngIf="errors.name">{{errors.name}}</div>
	</div>
 <div class="form-group row">
   <div class="col-md-2">
     <label class="col-form-label">Actief:</label>
   </div>
   <div class="col-md-10">
     <div class="custom-control custom-switch">
       <input type="checkbox" class="custom-control-input" id="active" name="active" [ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
       <label class="custom-control-label" for="active"></label>
     </div>
   </div>
 </div>
	<div class="form-group">
		<label for="email">E-mail</label>
		<input type="text" *ngIf="item" class="form-control" id="email" required [(ngModel)]="item.email">
		<div class="error" *ngIf="errors.email">{{errors.email}}</div>
	</div>
<div class="form-group">
  <label for="role">School</label>
  <select name="role" [(ngModel)]="item.organisationId" class="form-control">
    <option [selected]="item['item.organisationId'] == organisation['id']" value="{{organisation['id']}}" *ngFor="let organisation of organisations">
       {{organisation['longName']}}
    </option>
  </select>
  <div class="error" *ngIf="errors.organisationId">{{errors.organisationId}}</div>
</div>
	<div class="form-group">
		<label for="role">Niveau</label>
		<select name="role" [(ngModel)]="item.studyLevel" class="form-control">
			<option value="{{studyLevel['value']}}" [selected]="item['studyLevel'] == studyLevel['value']" *ngFor="let studyLevel of studyLevels">
				{{studyLevel['name']}}
			</option>
		</select>
		<div class="error" *ngIf="errors.studyLevel">{{errors.studyLevel}}</div>
	</div>

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Klassen:</label>
		</div>
		<div class="col-md-10">
			<span class="pt-2 d-inline-block" *ngFor="let classroomText of item['classroomTexts'];index as i; last as isLast">
				{{classroomText}}{{isLast ? '' : ', '}}
			</span>
		</div>
	</div>

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Gebruikt Entree:</label>
		</div>
		<div class="col-md-10">
			<span class="d-inline-block statePin" [ngClass]="{'active': item.isExternal}">
				{{boolToState(item.isExternal)}}
			</span>
		</div>
	</div>

  <div class="form-group">
    <label for="role">Rol</label>
    <select name="role" [(ngModel)]="item.role" class="form-control">
      <option value="{{role.value}}" *ngFor="let role of roles">
        {{role.name}}
      </option>
    </select>
    <div class="error" *ngIf="errors.role">{{errors.role}}</div>
  </div>

	<div class="form-group row">
		<div class="col-md-2">
			<label class="col-form-label">Wachtwoord wijzigen:</label>
		</div>
		<div class="col-md-10">
			<div class="custom-control custom-switch">
			  <input type="checkbox" class="custom-control-input" id="changepassword" (change)="checkChangepassword($event)" [(ngModel)]="item['changepassword']" name="changepassword">
			  <label class="custom-control-label" for="changepassword"></label>
			</div>
		</div>
	</div>

	<div class="changepasswordContainer" *ngIf="changepassword == true">
		<div class="form-group">
			<label for="adminpassword">Uw wachtwoord</label>
			<div style="height:0px;overflow:hidden;"><input type="text" name="username" id="username" autocomplete="username" value="" /><br><br></div>	
			<input type="password" name="password" class="form-control" id="adminpassword" required  [(ngModel)]="item['adminpassword']" autocomplete="current-password">
			<div class="error" *ngIf="errors.adminpassword">{{errors.adminpassword}}</div>
		</div>
		<div class="form-group">
			<label for="newpassword">Nieuw wachtwoord</label>
			<input type="password" class="form-control" id="newpassword" required  [(ngModel)]="item['newpassword']">
			<div class="error" *ngIf="errors.newpassword">{{errors.newpassword}}</div>
		</div>
		<div class="form-group">
			<label for="repeatpassword">Herhaal wachtwoord</label>
			<input type="password" class="form-control" id="repeatpassword" required  [(ngModel)]="item['repeatpassword']">
			<div class="error" *ngIf="errors.repeatpassword">{{errors.repeatpassword}}</div>
		</div>
	</div>

	<button (click)="save()" class="btn btn-primary">
		<span *ngIf="!item.id">Toevoegen</span>
		<span *ngIf="item.id">Opslaan</span>
	</button>
</section>

<section *ngIf="tab == 'logs'">
	<div class="table-search mb-1">
		<input (keyup)="search($event, activitiesOriginal)">
	</div>
	<table class="table">
		<thead class="thead-dark">
			<tr>
				<th width="10">#</th>
				<th scope="col" class="sortable"
					[ngClass]="{ 'sort-asc': sortColumns['date']['direction'] == 'asc', 'sort-desc': sortColumns['date']['direction'] == 'desc'}"
					(click)="sort('date', activityLogs)">Datum</th>
				<th scope="col" class="sortable"
					[ngClass]="{ 'sort-asc': sortColumns['modifiedUsername']['direction'] == 'asc', 'sort-desc': sortColumns['modifiedUsername']['direction'] == 'desc'}"
					(click)="sort('modifiedUsername', activityLogs)">Gebruiker</th>
				<th scope="col" class="sortable"
					[ngClass]="{ 'sort-asc': sortColumns['responsibleUsername']['direction'] == 'asc', 'sort-desc': sortColumns['responsibleUsername']['direction'] == 'desc'}"
					(click)="sort('responsibleUsername', activityLogs)">Door</th>
				<th scope="col" class="sortable"
					[ngClass]="{ 'sort-asc': sortColumns['type']['direction'] == 'asc', 'sort-desc': sortColumns['type']['direction'] == 'desc'}"
					(click)="sort('type',activityLogs)">Type</th>
				<th scope="col" class="sortable"
					[ngClass]="{ 'sort-asc': sortColumns['description']['direction'] == 'asc', 'sort-desc': sortColumns['description']['direction'] == 'desc'}"
					(click)="sort('description',activityLogs)">Beschrijving</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let activity of activityLogs | paginate: paginationConfig; let i = index"
				[ngClass]="{ 'd-none': activity.hidden == true }">
				<td>{{i + 1}}</td>
				<td>
					{{activity.date}} 
				</td>
				<td>
					<a [routerLink]="['/user/form/'+activity.modifiedUserId+'']">{{activity.modifiedUsername}}</a>
				</td>
				<td>
					<a [routerLink]="['/user/form/'+activity.responsibleUserId+'']">{{activity.responsibleUsername}}</a>
				</td>
				<td>
					{{ activity.typeText }}
				</td>
				<td>
					{{activity.changedValuesText}}
				</td>
			</tr>
		</tbody>
	</table>
</section>
</ng-container>
<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>
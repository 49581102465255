import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { ApiConnectionService }  from '../../services/core/api-connection.service';
import { ConfigService }  from '../../services/core/config.service';
import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-examinations-form',
  templateUrl: './examinations-form.component.html',
  styleUrls: ['./examinations-form.component.scss']
})
export class ExaminationsFormComponent implements OnInit {

	item: any = {};
	examinationFile: any = {};
	answersFile: any = {};
	error: boolean = false;
	errors: any = [];
	errormessage = false;
	successmessage = false;

	isDisabled = false;

	sections: any = [];
	chapters: any = [];
	studyLevels: any = [];

	contentUploadFile: any = false;
	contentUploadFileInProgress: boolean = false;

	answerUploadFile: any = false;
	answerUploadFileInProgress: boolean = false;

	apiUrl = '';

	revisions: any = [];

  constructor
  (
    private route: ActivatedRoute,
    private apiService: ApiConnectionService,
    private router: Router,
    private configService: ConfigService,
    private modalService: NgbModal,
    private ngxLoader: NgxUiLoaderService  
  ) { 
	this.apiUrl = configService.get('api_url');
  }

  ngOnInit(): void {
    let self = this;		

		this.studyLevels = this.configService.get('study_levels');
		this.studyLevels.reverse();
	
		this.fetchItem();
  }

  fetchItem () {
		let id = this.route.snapshot.paramMap.get('id');

		if (id != '0') {

			let self = this;
			this.ngxLoader.start();
			this.apiService.read('examinations/details/' + id).then((response) => {
				self.item = response;
				self.revisions = response['examinationRevisions'];
				self.revisions = self.revisions.sort(((a, b) => {
					return <any>new Date(b.createdAt) - <any>new Date(a.createdAt);
				}));
				
    			self.setSections();

				self.isDisabled = true;
				self.ngxLoader.stop();
			});
		} else {
			this.setSections();			
		}
	}

	requestDownload (examinationId)
	{
	  let self = this;
	  this.ngxLoader.start();
	  self.apiService.read('examinations/request/'+examinationId+'').then(function (response) {
			location.href = response['downloadUrl'];
		 	self.ngxLoader.stop();
	  });
	}

  setSections() {
    let self = this;

    this.ngxLoader.start();
    this.apiService.read('sections/all').then((response) => {
			self.sections = response;
      		self.setChaptersInitial();
			self.ngxLoader.stop();
	});
  }

  setChaptersInitial() {
    if (this.item.sectionId != null) {
      let self = this;

      this.ngxLoader.start();
      this.apiService.read('chapters/list/' + this.item.sectionId).then((response) => {
        self.chapters = response;
      });
    }
  }

  setChaptersOnSectionChange($event) {
    let id = $event.target.value;
    let self = this;

    this.ngxLoader.start();
    this.apiService.read('chapters/list/' + id).then((response) => {
			self.chapters = response;
			self.ngxLoader.stop();
		});
  }

  save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;
		
		this.apiService.save('examinations/save', self.item).then((response) => {
	      self.item = response;
	      self.ngxLoader.stop();
	      self.successmessage = true;
        self.router.navigate(['/examination/form/' + self.item['id']]);
		}).catch (function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	deleteContentFile (item) {
		let self = this;
		this.ngxLoader.start();
		this.apiService.delete('examinations/deleteContentFile/'+item.id+'').then((response) => {
			self.ngxLoader.stop();
			
			self.fetchItem();
		}).catch (function (error) {
			self.ngxLoader.stop();
		});	
	}
	
	confirmDeleteContentFile(item) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je het bestand wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.deleteContentFile(item);
			}
		});
	}
	
	uploadContentFile (files: FileList) {
		this.contentUploadFile = files.item(0);
		
		let self = this;
		
		const formData: FormData = new FormData();
		formData.append('file', this.contentUploadFile, this.contentUploadFile.name);
		self.contentUploadFileInProgress = true;
		
		this.apiService.saveFile('examinations/saveContentFile/'+this.item.id+'', formData).then((response) => {
			self.ngxLoader.stop();
			self.successmessage = true;
			self.contentUploadFile = false;
			self.contentUploadFileInProgress = false;
			
			self.fetchItem();
		}).catch (function (response) {
			console.error(response);
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
			self.contentUploadFileInProgress = false;
		});
	}

	/*
	*
	*/
	deleteAnswerFile (item) {
		let self = this;
		this.ngxLoader.start();
		this.apiService.delete('examinations/deleteAnswerFile/'+item.id+'').then((response) => {
			self.ngxLoader.stop();
			
			self.fetchItem();
		}).catch (function (error) {
			self.ngxLoader.stop();
		});	
	}
	
	confirmDeleteAnswerFile(item) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je het bestand wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.deleteAnswerFile(item);
			}
		});
	}
	
	uploadAnswerFile (files: FileList) {
		this.answerUploadFile = files.item(0);
		
		let self = this;
		
		const formData: FormData = new FormData();
		formData.append('file', this.answerUploadFile, this.answerUploadFile.name);
		self.answerUploadFileInProgress = true;
		
		this.apiService.saveFile('examinations/saveAnswerFile/'+this.item.id+'', formData).then((response) => {
			self.ngxLoader.stop();
			self.successmessage = true;
			self.answerUploadFile = false;
			self.answerUploadFileInProgress = false;
			
			self.fetchItem();
		}).catch (function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
			self.answerUploadFileInProgress = false;
		});
	}

	confirmDeleteFile(revision) {
		let self = this;
	
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je het bestand wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.deleteFile(revision);
			}
		});
	}

	deleteFile(revision) {
		let self = this;
		this.ngxLoader.start();
		this.apiService.delete('examinations/deleteFile/' + revision.id).then((response) => {
			self.ngxLoader.stop();
			self.fetchItem();
		}).catch (function (error) {
			console.error(error);
			self.ngxLoader.stop();
		});	
	}
}

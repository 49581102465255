<ng-container *ngIf="isAdmin">

<h1>
	Scholen
	<button routerLink="/school/form/0" class="btn btn-sm btn-primary mr-2">Toevoegen</button>
	<a [href]="getSchoolExport()" target="_blank"><button class="btn btn-sm btn-secondary">Exporteer BGN</button></a>
</h1>

<div class="table-search mb-1">
	<input (keyup)="search($event, organisationsOriginal)">
</div>
<table class="table">
  <thead class="thead-dark">
    <tr> 
	  <th width="10">#</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['name']['direction'] == 'asc', 'sort-desc': sortColumns['name']['direction'] == 'desc'}" (click)="sort('name', organisations)">School</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['city']['direction'] == 'asc', 'sort-desc': sortColumns['location']['direction'] == 'desc'}"  (click)="sort('city',organisations)">Plaats</th>
	  <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['authorityNumber']['direction'] == 'asc', 'sort-desc': sortColumns['authorityNumber']['direction'] == 'desc'}" (click)="sort('authorityNumber', organisations)">BGN</th>
	  <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['region']['direction'] == 'asc', 'sort-desc': sortColumns['region']['direction'] == 'desc'}"  (click)="sort('region',organisations)">Regio</th>
	  <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['classroomCount']['direction'] == 'asc', 'sort-desc': sortColumns['classroomCount']['direction'] == 'desc'}"  (click)="sort('classroomCount',organisations)">Klassen</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['studentCount']['direction'] == 'asc', 'sort-desc': sortColumns['studentCount']['direction'] == 'desc'}"  (click)="sort('studentCount',organisations)">Leerlingen</th>
      <th scope="col" class="sortable" [ngClass]="{ 'sort-asc': sortColumns['active']['direction'] == 'asc', 'sort-desc': sortColumns['active']['direction'] == 'desc'}"  (click)="sort('active',organisations)" width="100">Status</th>
	  <th scope="col" width="100">Verlengen</th>
      <th scope="col" width="100">Acties</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let organisation of organisations | paginate: paginationConfig; let i = index" [ngClass]="{ 'd-none': organisation.hidden == true }">
		<td>{{i + 1}}</td>
		<td><a routerLink="/school/form/{{organisation.id}}">{{organisation.name}} <span *ngIf="organisation.location"> ({{organisation.location}})</span></a>
			<span class="text-danger" *ngIf="organisation.hasOverusedLicenses"><i class="fas fa-exclamation-triangle"></i></span></td>
		<td>
			{{organisation.city}}
		</td>
		<td>
			{{organisation.authorityNumber}}
			<span *ngIf="organisation.gdprAccepted">Ja</span>
			<span *ngIf="!organisation.gdprAccepted">Nee</span>
		</td>
		<td>
			{{ organisation.region }}
		</td>
		<td>
			{{organisation.classroomCount}}
		</td>
		<td>
			{{organisation.studentCount}}
		</td>
		<td>
			<div *ngIf="organisation.active==1">Actief</div>
			<div *ngIf="organisation.active==0">Niet actief</div>
		</td>

		<td>
			<div *ngIf="organisation.extendIntention==1">Ja</div>
			<div *ngIf="organisation.extendIntention==''">Onb.</div>
		</td>

		<td>
	    	<a routerLink="/school/form/{{organisation.id}}" class="edit-link"><i class="fas fa-edit"></i></a>  &nbsp;
	    	<span class="delete-link" (click)="confirmDelete(organisation)"><i class="fas fa-trash"></i></span>
	    </td>
    </tr>
  </tbody>
</table>

<pagination-controls class="pagination" (pageChange)="pageChanged($event)"></pagination-controls>
</ng-container>
<ng-container *ngIf="!isAdmin">
	<p>Je hebt geen toegang tot deze pagina.</p>
</ng-container>
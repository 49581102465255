import { Component, OnInit } from '@angular/core';
import { ApiConnectionService }  from '../../services/core/api-connection.service';
import { ConfigService }  from '../../services/core/config.service';
import { SectionsService }  from '../../services/sections.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-check-images',
  templateUrl: './check-images.component.html',
  styleUrls: ['./check-images.component.scss']
})
export class CheckImagesComponent implements OnInit {
	imagesOriginal: any = [];
	images : any = [];
	frontUrl: '';
	currentSectionId: '';
	sectionIndex: '';
	sections : any = [];
	chapters : any = [];

	activeChapters: any = [];
	filteredImages: any = [];

	storedKeyword: string = '';
	
	constructor(
		public apiConnectionService: ApiConnectionService,
		public sectionsService: SectionsService,
		private ngxLoader: NgxUiLoaderService,
		private dom: DomSanitizer,
		public configService: ConfigService,
		public collectionManager: CollectionManagerService,
		private formBuilder: FormBuilder
	) {
		this.frontUrl = this.configService.get('front_url');
		let self = this;
		this.sectionsService.read('sections/all').then((response) => {
		  self.sections = response;
		});
	}

	text (content) {
		return this.dom.bypassSecurityTrustHtml(content);
	}

	ngOnInit(): void {
		this.fetchItems();  
	}

	setCurrentSectionIndex (index)
	{
		this.imagesOriginal = [];
		this.images = [];
		this.activeChapters = [];
		this.sectionIndex = index;
		this.fetchItems();
	}

	fetchItems () {
		let self = this;
		this.apiConnectionService.read('lessons/images/'+self.sectionIndex+'').then((response) => {
			self.imagesOriginal = response['images'];
			self.images = response['images'];
			self.setChapters();
		});;
	}

	search (event) {
		let keyword = '';

		if (event === false) {
			keyword = this.storedKeyword;
		} else {
			keyword = event.target.value;
		}
		
		// als er geen chapters zijn geselecteerd, voer dan de normale flow uit
		if (this.activeChapters.length === 0) {
			this.images = this.imagesOriginal.slice();

			if (keyword != '') {
				this.storedKeyword = keyword;
				// this.paginationConfig.currentPage = 1;
				this.images = this.collectionManager.search(keyword, this.imagesOriginal).slice();
			}
		// als er wel chapters zijn geselecteerd, reset dan de originele array gefilterd op huidige chapters
		} else {
			this.storedKeyword = keyword;
			this.images = this.filteredImages.slice();

			if (keyword != '') {
				// this.paginationConfig.currentPage = 1;
				this.images = this.collectionManager.search(keyword, this.filteredImages).slice();
			}
		}
	}

	visitLesson (chapterLessonId, studyLevel) {
		let self = this;
		this.ngxLoader.start();
		this.apiConnectionService.read('lessons/visitImage/'+chapterLessonId+'/'+studyLevel+'').then((response) => {
			window.open(response['link'], "_blank" );
		}).catch (function (error) {
			self.ngxLoader.stop();
		});
	}

	setChapters() {
		this.chapters = [];

		if (this.imagesOriginal != null) {
			for (let i = 0; i < this.imagesOriginal.length; i++) {
				if (this.imagesOriginal[i]['chapters'].length > 0) {
					let chapter = this.imagesOriginal[i]['chapters'][0]['chapter'];

					let index = this.chapters.findIndex(object => object['id'] === chapter['id']);

					if (index === -1) {
						this.chapters.push(chapter);
					}
				}
			}
		}
	}

	filterImages() {
		this.images = this.imagesOriginal.slice();
		this.images = this.images.filter(image => this.activeChapters.indexOf(image['chapters'][0]['chapter']['id']) !== -1);
		this.filteredImages = this.images.filter(image => this.activeChapters.indexOf(image['chapters'][0]['chapter']['id']) !== -1);

		if (this.storedKeyword !== '') {
			this.search(false);
		}

		if (this.activeChapters.length === 0) {
			this.filteredImages = this.imagesOriginal.slice();
			this.images = this.imagesOriginal.slice();
		}
	} 

	addFilterValue(chapterId) {
		let indexOf = this.activeChapters.indexOf(chapterId);
		if (indexOf === -1) {
			this.activeChapters.push(chapterId);
		} else {
			this.activeChapters.splice(indexOf, 1);
		}
		this.filterImages();
	}
}

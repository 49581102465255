import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogService {

	constructor (
		private http: HttpClient
	) {
		
	}
	
	sendReport (url, status, message) {
		let postData = {};
			postData['url'] = url;
			postData['status'] = status;
			postData['message'] = message;			
			
		this.http.post(
				''+environment['apiUrl']+'application/Jobs/Report.php', 
				postData, 
				{
					headers : {
						'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
					}
				}
			).subscribe({
			next: data => {
				
			}
		})
	}
}

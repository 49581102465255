import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OrganisationsService }  from '../../services/organisations.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-schools-index',
  templateUrl: './schools-index.component.html',
  styleUrls: ['./schools-index.component.scss']
})

export class SchoolsIndexComponent implements OnInit {
  organisationsOriginal: any = [];
  organisations : any = [];
  sortColumns = {
  	'name' : { type: 'string', direction: ''},
  	'authorityNumber' : { type: 'string', direction: ''},
  	'location' : { type: 'string', direction: ''},
  	'city' : { type: 'string', direction: ''},
	'region' : { type: 'string', direction: ''},
  	'studentCount' : { type: 'numeric', direction: ''},
  	'classroomCount' : { type: 'numeric', direction: ''},
  	'licenseCount' : { type: 'numeric', direction: ''},
  	'active' : { type: 'numeric', direction: ''}
  };

  paginationConfig: PaginationConfig = new PaginationConfig();
  
  constructor(
  	public organisationsService: OrganisationsService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private ngxLoader: NgxUiLoaderService,
	  private authenticationService: AuthenticationService
  ) { }

  isAdmin: boolean;

  ngOnInit(): void {
    this.authenticationService.isCurrentUserAdmin().then((data) => {
      this.isAdmin = data;
    });
	this.all();
  }

  getSchoolExport() {
	return this.authenticationService.apiUrl + "/organisations/bgn";
  }
  
  all () {
    let self = this;
    this.ngxLoader.start();
	this.organisationsService.read('organisations').then((response) => {
	  self.organisationsOriginal = response;
      self.organisations = response;
      self.ngxLoader.stop();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});
  }
  
  delete (organisation) {
    let self = this;
    this.ngxLoader.start();
	this.organisationsService.delete('organisations/delete/'+organisation.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
	let keyword = event.target.value;
	this.organisations = this.organisationsOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.organisations = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
  
  confirmDelete(organisation) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je '+organisation.name+' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(organisation);
		}
	});
  }
}

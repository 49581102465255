import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

import { ApiConnectionService }  from '../../services/core/api-connection.service';

import { PaginationConfig } from 'src/app/models/paginationConfig';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})

export class LogsComponent implements OnInit {
  logsOriginal: any = [];
  logs : any = [];
  sortColumns = {
  	'url' : { type: 'string', direction: ''}
  };
  section : any = {};

  paginationConfig: PaginationConfig = new PaginationConfig();
  
  constructor(
	public api: ApiConnectionService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private route: ActivatedRoute,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
	this.all();
  }
  
  all () {
    let self = this;
    
    this.ngxLoader.start();
	  this.api.read('logs/all').then((response) => {
      self.logsOriginal = response;	
      self.logs = response;
      self.ngxLoader.stop();
	  });
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
    let keyword = event.target.value;
    this.logs = this.logsOriginal.slice();
    if (keyword != '') {
      this.paginationConfig.currentPage = 1;
      this.logs = this.collectionManager.search(keyword, collection).slice();
    }
  }
  
  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
}

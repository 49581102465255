<div class="alert alert-warning" role="alert" *ngIf="error">
  Inloggegevens zijn niet juist
</div>
<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()" >
	<div class="form-group">
	<label>Email </label>
	<input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" (change)="filterEmailSpaces()" />
	<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
		<div *ngIf="f.email.errors.required">Email is required</div>
		<div *ngIf="f.email.errors.email">Email must be a valid email address</div>
	</div>
	</div>
	<div class="form-group">
		<label>Wachtwoord</label>
		<input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
		<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
			<div *ngIf="f.password.errors.required">Password is required</div>
			<div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
		</div>
	</div>
	<button type="submit" class="btn btn-primary">Inloggen</button>
</form>
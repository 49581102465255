import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter   } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

import { ApiConnectionService }  from '../../services/core/api-connection.service';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { UsersService }  from '../../services/users.service';
import { ConfigService }  from '../../services/core/config.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})

export class FeedbackComponent implements OnInit {
  feedbacks : any = [];
  frontUrl = '';
  
  constructor(
	public api: ApiConnectionService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private route: ActivatedRoute,
  	private usersService: UsersService,
	public configService: ConfigService,
  	private ngxLoader: NgxUiLoaderService
  ) {
		this.frontUrl = this.configService.get('front_url');
  }

  ngOnInit(): void {
	this.all();
  }
  
  all () {
    let self = this;
    
    this.ngxLoader.start();
	this.api.read('lessonFeedbacks/all').then((response) => {
      self.feedbacks = response;
      self.ngxLoader.stop();
	});
	
	this.api.read('lessonFeedbacks/readAll').then((response) => {
		this.usersService.getUnreadCount();
	});
  }

  getActiveItems ()
  {
	let items = [];
	for (let i=0;i<this.feedbacks.length;i++)
	{
	  let feedback = this.feedbacks[i];
	  console.dir(feedback);
	  if (feedback['archive'] < 1) 
	  {
		  items.push(feedback);
	  }
	}
	return items;
  }

  getArchiveItems ()
  {
	  let items = [];
	  for (let i=0;i<this.feedbacks.length;i++)
	  {
		let feedback = this.feedbacks[i];
		if (feedback['archive'] > 0) 
		{
			items.push(feedback);
		}
	  }
	  return items;
  }

  dearchive (feedback) {
    let self = this;
    this.ngxLoader.start();
	this.api.delete('lessonFeedbacks/dearchive/'+feedback.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }

  archive (feedback) {
    let self = this;
    this.ngxLoader.start();
	this.api.delete('lessonFeedbacks/archive/'+feedback.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  delete (feedback) {
    let self = this;
    this.ngxLoader.start();
	this.api.delete('lessonFeedbacks/delete/'+feedback.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});
  }

  visitLessonFeedBack(lessonFeedbackId, chapterId) {
    let self = this;
    this.ngxLoader.start();
	this.api.read('lessonFeedbacks/visit/'+lessonFeedbackId+'/'+chapterId+'').then((response) => {
		window.open(response['link'], "_blank" );
	}).catch (function (error) {
		self.ngxLoader.stop();
	});
  }
  
  confirmDelete(feedback) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je deze feedback wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(feedback);
		}
	});
  }
}

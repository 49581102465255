import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/services/users.service';
import { CollectionManagerService } from 'src/app/services/core/collection-manager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-users-inactive',
  templateUrl: './users-inactive.component.html',
  styleUrls: ['./users-inactive.component.scss']
})
export class UsersInactiveComponent implements OnInit {
    users: any = [];
    sortColumns = {
      'name': { type: 'string', direction: '' },
      'lastLogin': { type: 'string', direction: '' },
      'deleteAfterMonths': { type: 'string', direction: '' },
      'organisationName': { type: 'string', direction: '' }
    };
  
    constructor(
      public usersService: UsersService,
      public collectionManager: CollectionManagerService,
      private modalService: NgbModal,
      private ngxLoader: NgxUiLoaderService,
      private authenticationService: AuthenticationService
    ) { }
  
    isAdmin: boolean;
  
    ngOnInit(): void {
      this.authenticationService.isCurrentUserAdmin().then((data) => {
        this.isAdmin = data;
      });
      this.all();
    }
  
    all() {
      let self = this;
      this.ngxLoader.start();
      this.usersService.read('users/inactive').then((response) => {
        self.users = response;
        self.ngxLoader.stop();
        self.sort('lastLogin', self.users);
      });
    }
  
    delete(user) {
      let self = this;
      this.ngxLoader.start();
      this.usersService.delete('users/delete/' + user.id + '').then((response) => {
        self.ngxLoader.stop();
        self.all();
      }).catch(function (error) {
        self.ngxLoader.stop();
      });
    }
  
    confirmDelete(user) {
      let self = this;
  
      const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
      deleteModal.componentInstance.message = 'Weet je zeker dat je ' + user.name + ' wilt verwijderen?';
      deleteModal.result.then((result) => {
        if (result) {
          this.delete(user);
        }
      });
    }
  
    sort(subject, collection) {
      if (!this.sortColumns.hasOwnProperty(subject))
        alert('Sorteer optie bestaat niet');
  
      for (let key in this.sortColumns) {
        if (key !== subject)
          this.sortColumns[key].direction = '';
      }
  
      let column = this.sortColumns[subject];
      this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
  
      this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
    }
  }
  
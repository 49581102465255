<h1>
	<span routerLink="/pages" class="btn btn-info mr-2"><i class="fas fa-arrow-left"></i></span>
	<span *ngIf="!item['id']">Pagina toevoegen</span>
	<span *ngIf="item.id">Pagina wijzigen</span>
</h1>


<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>

	<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item.id" name="id">
	<br/><br/>
	Titel:
	<input type="text" *ngIf="item" class="form-control" id="name" required [(ngModel)]="item.title" name="title">
	<div class="error" *ngIf="errors.name">{{errors.name}}</div>
	
	<br/><br/>
	
	Description:
	<input type="text" *ngIf="item" class="form-control" id="seoDescription" required [(ngModel)]="item.seoDescription" name="seoDescription">
	   <div class="error" *ngIf="errors.seoDescription">{{errors.seoDescription}}</div>
	<br/><br/>
	
	Slug:
	<input type="text" *ngIf="item" class="form-control" id="slug" required [(ngModel)]="item.slug" name="slug">
	   <div class="error" *ngIf="errors.slug">{{errors.slug}}</div>
	<br/><br/>
	

	<div class="form-group row">
		<div class="col-md-2">
			<label for="title" class="col-form-label">Omschrijving</label>
		</div>
		<div class="col-md-10">
			<textarea style="height:400px;" *ngIf="item" class="form-control" [ngClass]="{ 'is-invalid': errors.content }" [(ngModel)]="item.content" id="content" name="description" placeholder="" required>{{item['content']}}</textarea>
			<div class="error" *ngIf="errors.content">{{errors.content}}</div>
		</div>
	</div>
	<div class="form-group row">
		<div class="col-md-2">
			<label for="video">Extern ID</label>
		</div>
		<div class="col-md-10">
			<input type="text" *ngIf="item" class="form-control" id="externalId" required [(ngModel)]="item.externalId" name="externalId">
			<div class="error" *ngIf="errors.externalId">{{errors.externalId}}</div>
		</div>
	</div>


	Actief:
	<input type="radio" *ngIf="item" id="active_1" [value]="1" [(ngModel)]="item.active" name="active"> Actief<br/>
	<input type="radio" *ngIf="item" id="active_inactive" [value]="0" [(ngModel)]="item.active" name="active"> Niet active <br/>
	        
			
	<br/><br/>
	<button (click)="save()" class="btn btn-primary">
		<span *ngIf="!item.id">Toevoegen</span>
		<span *ngIf="item.id">Opslaan</span>
	</button>
        
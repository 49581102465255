import { Component, OnInit } from '@angular/core';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ActivatedRoute } from '@angular/router';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { PaginationConfig } from 'src/app/models/paginationConfig';

@Component({
  selector: 'app-examinations-index',
  templateUrl: './examinations-index.component.html',
  styleUrls: ['./examinations-index.component.scss']
})
export class ExaminationsIndexComponent implements OnInit {

  examinationsOriginal: any = [];
  examinations : any = [];
  sortColumns = {
  	'title' : { type: 'string', direction: ''}, 
  	'variant' : { type: 'string', direction: ''}, 
  	'version' : { type: 'numeric', direction: ''}, 
  	'section' : { type: 'string', direction: ''},
  	'createdAt' : { type: 'numeric', direction: ''},
  	'updatedAt' : { type: 'numeric', direction: ''}
  };

  paginationConfig: PaginationConfig = new PaginationConfig();

  constructor
  (
	private apiService: ApiConnectionService, 
    public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private ngxLoader: NgxUiLoaderService,
  	private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
	  this.all();
  }
  
  all () {
    let self = this;
    
    let url = 'examinations/all';
    
    this.ngxLoader.start();
	  this.apiService.read(url).then((response) => {
	  self.examinationsOriginal = response;	
      self.examinations = response;
      self.ngxLoader.stop();
	});
  }
  
  delete (examination) {
    let self = this;
    this.ngxLoader.start();
	this.apiService.delete('examinations/delete/' + examination.id).then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});	
  }
  
  confirmDelete(examination) {
  	let self = this;
  	
	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je ' + examination.title + ' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(examination);
		}
	});
  }
  
  sort (subject, collection) {
 	if(!this.sortColumns.hasOwnProperty(subject))
 		alert('Sorteer optie bestaat niet');
 		
 	for (let key in this.sortColumns) {
 		if (key !== subject)
	  		this.sortColumns[key].direction = '';
	}
 	
 	let column = this.sortColumns[subject];
 	this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');
 	
    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }
  
  search (event, collection) {
	let keyword = event.target.value;
	this.examinations = this.examinationsOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.examinations = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
}

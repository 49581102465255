import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PagesService }  from '../../services/pages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pages-form',
  templateUrl: './pages-form.component.html',
  styleUrls: ['./pages-form.component.scss']
})
export class PagesFormComponent implements OnInit {
	error : boolean = false;
	item : any = {};
	errors : any = [];
	mutateForm;
	errormessage = false;
	successmessage = false;
	
	constructor(
  		public service: PagesService,
  		private route: ActivatedRoute,
  		private ngxLoader: NgxUiLoaderService
	) { }

	ngOnInit(): void {
		this.fetchItem();
	}
	
	fetchItem () {
		let id = this.route.snapshot.paramMap.get('id');

		let self = this;
	    this.ngxLoader.start();
		this.service.read('pages/details/'+id+'').then((response) => {
	      self.item = response;
	      self.ngxLoader.stop();
		});
	}
	
	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;
		
		this.service.save('pages/save', self.item).then((response) => {
	      self.item = response;
	      self.ngxLoader.stop();
	      self.successmessage = true;
		}).catch (function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

}

import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UsersService } from '../../services/users.service';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
	selector: 'app-users-index',
	templateUrl: './users-index.component.html',
	styleUrls: ['./users-index.component.scss']
})

export class UsersIndexComponent implements OnInit {
	usersOriginal: any = [];
	users: any = [];
	sortColumns = {
		'name': { type: 'string', direction: '' },
		'lastLogin': { type: 'string', direction: '' },
		'regDate': { type: 'string', direction: '' },
		'organisationName': { type: 'string', direction: '' },
		'roleText': { type: 'string', direction: '' },
		'active': { type: 'numeric', direction: '' }
	};

	paginationConfig: PaginationConfig = new PaginationConfig();

	constructor(
		public usersService: UsersService,
		public collectionManager: CollectionManagerService,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService,
		private authenticationService: AuthenticationService
	) { }

	isAdmin: boolean;

	ngOnInit(): void {
		this.authenticationService.isCurrentUserAdmin().then((data) => {
			this.isAdmin = data;
		});
		this.all();
	}

	all() {
		let self = this;
		this.ngxLoader.start();
		this.usersService.read('users').then((response) => {
			self.usersOriginal = response;
			self.users = response;
			self.paginationConfig.count = self.users.length;
			self.ngxLoader.stop();
			self.sort('lastLogin', self.users);
		});
	}

	delete(user) {
		let self = this;
		this.ngxLoader.start();
		this.usersService.delete('users/delete/' + user.id + '').then((response) => {
			self.ngxLoader.stop();
			self.all();
		}).catch(function (error) {
			self.ngxLoader.stop();
		});
	}

	confirmDelete(user) {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je ' + user.name + ' wilt verwijderen?';
		deleteModal.result.then((result) => {
			if (result) {
				this.delete(user);
			}
		});
	}

	sort(subject, collection) {
		if (!this.sortColumns.hasOwnProperty(subject))
			alert('Sorteer optie bestaat niet');

		for (let key in this.sortColumns) {
			if (key !== subject)
				this.sortColumns[key].direction = '';
		}

		let column = this.sortColumns[subject];
		this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');

		this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
	}

	search(event, collection) {
		let keyword = event.target.value;
		this.users = this.usersOriginal.slice();
		if (keyword != '') {
			this.paginationConfig.currentPage = 1;
			this.users = this.collectionManager.search(keyword, collection).slice();
		}
	}

	pageChanged($event) {
		this.paginationConfig.currentPage = $event;
	}
}

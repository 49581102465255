import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SectionsService }  from '../../services/sections.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';

import { PaginationConfig } from 'src/app/models/paginationConfig';

@Component({
  selector: 'app-sections-index',
  templateUrl: './sections-index.component.html',
  styleUrls: ['./sections-index.component.scss']
})
export class SectionsIndexComponent implements OnInit {
	sectionsOriginal: any = [];
  sections : any = [];
  sortColumns = {
  	'name' : { type: 'string', direction: 'desc'},
  	'position' : { type: 'numeric', direction: ''},
  	'active' : { type: 'numeric', direction: ''}
  };

  paginationConfig: PaginationConfig = new PaginationConfig();

  constructor(
  	public sectionsService: SectionsService,
  	public collectionManager: CollectionManagerService,
  	private modalService: NgbModal,
  	private ngxLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
	this.all();
  }

  sort (subject, collection) {
    if(!this.sortColumns.hasOwnProperty(subject))
      alert('Sorteer optie bestaat niet');

    for (let key in this.sortColumns) {
      if (key !== subject)
          this.sortColumns[key].direction = '';
    }

 	  let column = this.sortColumns[subject];
 	  this.sortColumns[subject].direction = (column.direction == 'desc' ? 'asc' : 'desc');

    this.collectionManager.sortNumeric(subject, collection, column.direction, column.type);
  }

  all () {
    let self = this;
    this.ngxLoader.start();
	  this.sectionsService.read('sections/all').then((response) => {
      self.sectionsOriginal = response;
        self.sections = response;
        self.ngxLoader.stop();
    });
  }

  getHomeTop () 
  {
    let items = [];
    
    for (let i = 0; i < this.sections.length; i++)
    {
      let section = this.sections[i];
      if (section['homeTop'] == 1)
      {
        items.push(section);
      }
    }

    items.sort((a, b) => {
      return a['position'] - b['position'];
    });

    return items;
  }

getHomeBottom ()
{
  let items = [];
  for (let i=0;i<this.sections.length;i++)
  {
  let section = this.sections[i];
  if (section['homeBottom'] == 1)
  {
    items.push(section);
  }
  }

  items.sort((a, b) => {
    return a['position'] - b['position'];
  });
  return items;
}

  delete (section) {
    let self = this;
    this.ngxLoader.start();
	this.sectionsService.delete('sections/delete/'+section.id+'').then((response) => {
		self.ngxLoader.stop();
		self.all();
	}).catch (function (error) {
		self.ngxLoader.stop();
	});
  }

  confirmDelete(section) {
  	let self = this;

	const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	deleteModal.componentInstance.message = 'Weet je zeker dat je '+section.name+' wilt verwijderen?';
	deleteModal.result.then((result) => {
		if (result) {
			this.delete(section);
		}
	});
  }

  search (event, collection) {
	let keyword = event.target.value;
	this.sections = this.sectionsOriginal.slice();
	if (keyword != '') {
		this.paginationConfig.currentPage = 1;
		this.sections = this.collectionManager.search(keyword, collection).slice();
	}
  }

  pageChanged($event) {
    this.paginationConfig.currentPage = $event;
  }
}

import { Component, OnInit } from '@angular/core';
import { ApiConnectionService }  from '../../services/core/api-connection.service';
import { ConfigService }  from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { SectionsService }  from '../../services/sections.service';
import { CollectionManagerService }  from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-check-quotes',
  templateUrl: './check-quotes.component.html',
  styleUrls: ['./check-quotes.component.scss']
})
export class CheckQuotesComponent implements OnInit {
	blockQuotesOriginal: any = [];
	blockQuotes : any = [];
	frontUrl: '';
	pageIndex: number;
	currentSectionId: '';
	sectionIndex: '';
	sections : any = [];
	chapters: any = [];

	activeChapters: any = [];
	filteredBlockQuotes: any = [];

	storedKeyword: string = '';

	constructor(
		public authenticationService: AuthenticationService, 
		public apiConnectionService: ApiConnectionService,
		private ngxLoader: NgxUiLoaderService,
		public sectionsService: SectionsService,
		public configService: ConfigService,
		public collectionManager: CollectionManagerService
	) {
		this.frontUrl = this.configService.get('front_url');
		this.pageIndex = 0;

		let self = this;
		this.sectionsService.read('sections/all').then((response) => {
		  self.sections = response;
		});
	}

	ngOnInit(): void {
		this.fetchItems();  
	}

	setCurrentSectionIndex (index)
	{
		this.blockQuotesOriginal = [];
		this.blockQuotes = [];
		this.activeChapters = [];
		this.sectionIndex = index;
		this.fetchItems();
	}

	fetchItems () {
		let self = this;
		this.apiConnectionService.read('lessons/quotes/'+self.sectionIndex+'').then((response) => {
			self.blockQuotesOriginal = response['blockQuotes'];
			self.blockQuotes = response['blockQuotes'];
			self.setChapters();
		});;
	}

	search (event) {
		let keyword = '';

		if (event === false) {
			keyword = this.storedKeyword;
		} else {
			keyword = event.target.value;
		}

		// als er geen chapters zijn geselecteerd, voer dan de normale flow uit
		if (this.activeChapters.length === 0) {
			this.blockQuotes = this.blockQuotesOriginal.slice();

			if (keyword != '') {
				this.storedKeyword = keyword;
				// this.paginationConfig.currentPage = 1;
				this.blockQuotes = this.collectionManager.search(keyword, this.blockQuotesOriginal).slice();
			}
		// als er wel chapters zijn geselecteerd, reset dan de originele array gefilterd op huidige chapters
		} else {
			this.blockQuotes = this.filteredBlockQuotes.slice();

			if (keyword != '') {
				this.storedKeyword = keyword;
				// this.paginationConfig.currentPage = 1;
				this.blockQuotes = this.collectionManager.search(keyword, this.filteredBlockQuotes).slice();
			}
		}
	}

	setChapters() {
		this.chapters = [];

		if (this.blockQuotesOriginal != null) {
			for (let i = 0; i < this.blockQuotesOriginal.length; i++) {
				if (this.blockQuotesOriginal[i]['chapters'].length > 0) {
					let chapter = this.blockQuotesOriginal[i]['chapters'][0]['chapter'];

					let index = this.chapters.findIndex(object => object['id'] === chapter['id']);

					if (index === -1) {
						this.chapters.push(chapter);
					}
				}
			}
		}
	}

	visitLesson (chapterLessonId, studyLevel) {
		let self = this;
		this.ngxLoader.start();
		this.apiConnectionService.read('lessons/visitQuote/'+chapterLessonId+'/'+studyLevel+'').then((response) => {
			window.open(response['link'], "_blank" );
		}).catch (function (error) {
			self.ngxLoader.stop();
		});
	}
	
	filterBlockQuotes() {
		this.blockQuotes = this.blockQuotesOriginal.slice();
		this.blockQuotes = this.blockQuotes.filter(blockQuote => this.activeChapters.indexOf(blockQuote['chapters'][0]['chapter']['id']) !== -1);
		this.filteredBlockQuotes = this.blockQuotes.filter(blockQuote => this.activeChapters.indexOf(blockQuote['chapters'][0]['chapter']['id']) !== -1);

		if (this.storedKeyword !== '') {
			this.search(false);
		}

		if (this.activeChapters.length === 0) {
			this.filteredBlockQuotes = this.blockQuotesOriginal.slice();
			this.blockQuotes = this.blockQuotesOriginal.slice();
		}
	} 

	addFilterValue(chapterId) {
		let indexOf = this.activeChapters.indexOf(chapterId);
		if (indexOf === -1) {
			this.activeChapters.push(chapterId);
		} else {
			this.activeChapters.splice(indexOf, 1);
		}
		this.filterBlockQuotes();
	}
}

<div class="modal-header">
	<h5 class="modal-title">{{title}}</h5>
	<button type="button" class="close" aria-label="Close" (click)="close()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body p-5" innerHTML="{{message}}">
</div>
<div class="modal-footer" *ngIf="button">
	<button (click)="confirm()" type="submit" class="btn btn-success">{{button}}</button>
</div>